@charset "UTF-8";
html {
  font-size: 20px;
}

body {
  min-width: 360px;
  color: #333333;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1;
  background-color: #fafafa;
}
@media (min-width: 768px) {
  body {
    font-size: 18px;
  }
}
@media (min-width: 1024px) {
  body {
    font-size: 20px;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 480px) {
  .container {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 480px) {
  .row {
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}
.row > * {
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 480px) {
  .row > * {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

.row-custom {
  height: calc(100% + 2rem);
}

.fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fit-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-abs {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1;
}

h2 {
  font-size: 20px;
}
@media (min-width: 480px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 20px;
}
@media (min-width: 768px) {
  h3 {
    font-size: 24px;
  }
}

h4 {
  font-size: 20px;
}

.img-wrap {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.img-wrap.grad .cover {
  z-index: 1;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 80%);
}
@media (min-width: 768px) {
  .img-wrap.grad .cover {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%);
  }
}

.ordinary-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}
@media (min-width: 1440px) {
  .ordinary-text {
    font-size: 20px;
  }
}
.ordinary-text p + p {
  margin-top: 16px;
}
.ordinary-text .sign {
  font-size: 12px;
  margin-top: 20px;
}
@media (min-width: 1024px) {
  .ordinary-text .sign {
    font-size: 16px;
    margin-top: 40px;
  }
}
.ordinary-text.v2 {
  font-size: 16px;
}
@media (min-width: 1024px) {
  .ordinary-text.v2 {
    font-size: 20px;
  }
}
.ordinary-text .icon-quot_left,
.ordinary-text .icon-quot_right {
  fill: #365edc;
}

* + .text-segm {
  margin-top: 60px;
}

.text-segm h3 {
  margin-bottom: 40px;
}
.text-segm h4 {
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .text-segm.right-img {
    display: flex;
  }
}
.text-segm.right-img .img-wrap {
  flex-shrink: 0;
  width: 100%;
  height: 300px;
  margin: 20px 0 0 0;
}
@media (min-width: 768px) {
  .text-segm.right-img .img-wrap {
    width: 440px;
  }
}
@media (min-width: 1024px) {
  .text-segm.right-img .img-wrap {
    margin: 0 0 0 20px;
  }
}
@media (min-width: 1440px) {
  .text-segm.right-img .img-wrap {
    width: 520px;
    height: 360px;
  }
}

.ol-list {
  list-style: decimal;
  color: #365edc;
  font-weight: 400;
  padding-left: 34px;
}
.ol-list li + li {
  margin-top: 24px;
}
.ol-list > li {
  padding-left: 6px;
}
.ol-list ul {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 20px;
  margin-top: 24px;
}

.ul-list {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 34px;
}
.ul-list > li + li {
  margin-top: 16px;
}
.ul-list > li::marker {
  color: #365edc;
}

a.text-link {
  color: #365edc;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: transparent;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 1px;
  transition: text-decoration-color 0.2s;
}
a.text-link:hover {
  text-decoration-color: #365edc;
}
a.text-link.v2 {
  text-decoration-style: dashed;
  text-decoration-color: #365edc;
}
a.text-link.v2:hover {
  text-decoration-style: solid;
}

figure .img-wrap {
  height: 280px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  figure .img-wrap {
    height: 420px;
  }
}
@media (min-width: 1024px) {
  figure .img-wrap {
    height: 600px;
  }
}
@media (min-width: 1440px) {
  figure .img-wrap {
    height: 700px;
  }
}
figure figcaption {
  color: #999999;
  font-weight: 400;
}

.bg-image-cover {
  background-position: center;
  background-color: #cccccc;
  background-size: cover;
  background-repeat: no-repeat;
}

.border-box {
  background-origin: border-box;
}

.accent-color {
  color: #365edc !important;
}

.color-grey1 {
  color: #999999 !important;
}

.fw400 {
  font-weight: 400;
}

@media (min-width: 768px) {
  .table-sepr-wrap {
    margin-right: -20px;
    margin-left: -20px;
  }
}
@media (min-width: 1440px) {
  .table-sepr-wrap {
    margin-right: -40px;
    margin-left: -40px;
  }
}

.table-type1 {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
}
@media (min-width: 768px) {
  .table-type1 {
    font-size: 16px;
  }
}
.table-type1 th {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  background-color: #365edc;
  padding: 14px 6px;
}
@media (min-width: 768px) {
  .table-type1 th {
    padding: 20px;
  }
}
@media (min-width: 1024px) {
  .table-type1 th {
    font-size: 20px;
  }
}
.table-type1 th:first-child {
  padding-left: 15px;
  border-top-left-radius: 12px;
}
@media (min-width: 768px) {
  .table-type1 th:first-child {
    padding-left: 40px;
  }
}
.table-type1 th:last-child {
  padding-right: 15px;
  border-top-right-radius: 12px;
}
@media (min-width: 768px) {
  .table-type1 th:last-child {
    padding-right: 40px;
  }
}
.table-type1 tr td {
  position: relative;
  vertical-align: middle;
  padding: 20px 6px;
}
@media (min-width: 768px) {
  .table-type1 tr td {
    padding: 20px;
  }
}
.table-type1 tr td::after {
  content: "";
  display: block;
  position: absolute;
  right: 6px;
  bottom: 0;
  left: 6px;
  border-bottom: 1px solid #f2f2f2;
}
@media (min-width: 768px) {
  .table-type1 tr td::after {
    right: 20px;
    left: 20px;
  }
}
.table-type1 tr td:first-child {
  text-align: left;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .table-type1 tr td:first-child {
    text-align: center;
    padding-left: 40px;
  }
}
.table-type1 tr td:first-child::after {
  left: 15px;
}
@media (min-width: 768px) {
  .table-type1 tr td:first-child::after {
    left: 40px;
  }
}
.table-type1 tr td:last-child {
  padding-right: 15px;
}
@media (min-width: 768px) {
  .table-type1 tr td:last-child {
    padding-right: 40px;
  }
}
.table-type1 tr td:last-child::after {
  right: 15px;
}
@media (min-width: 768px) {
  .table-type1 tr td:last-child::after {
    right: 40px;
  }
}
.table-type1 tr:first-child td {
  padding-top: 40px;
}
.table-type1 tr:last-child td {
  padding-bottom: 40px;
}
.table-type1 tr:last-child td::after {
  border: none;
}
.table-type1.separate {
  background-color: transparent;
  border-collapse: separate;
  border-spacing: 0;
}
@media (min-width: 768px) {
  .table-type1.separate {
    border-spacing: 20px 0;
  }
}
@media (min-width: 1440px) {
  .table-type1.separate {
    border-spacing: 40px 0;
  }
}
.table-type1.separate tr th {
  font-size: 12px;
  padding: 20px 6px;
}
@media (min-width: 768px) {
  .table-type1.separate tr th {
    font-size: 16px;
    padding: 20px;
    border-radius: 12px 12px 0 0;
  }
}
@media (min-width: 1024px) {
  .table-type1.separate tr th {
    font-size: 20px;
    padding: 20px 40px;
  }
}
.table-type1.separate tr td {
  background-color: #fff;
  padding: 20px 10px;
}
@media (min-width: 768px) {
  .table-type1.separate tr td {
    padding: 20px;
  }
}
@media (min-width: 1024px) {
  .table-type1.separate tr td {
    padding: 20px 40px;
  }
}
.table-type1.separate tr td:nth-child(1) {
  width: 65%;
}
@media (min-width: 768px) {
  .table-type1.separate tr td:nth-child(1) {
    width: 45%;
  }
}
@media (min-width: 1440px) {
  .table-type1.separate tr td:nth-child(1) {
    width: 50%;
  }
}
.table-type1.separate tr td:nth-child(2), .table-type1.separate tr td:nth-child(3) {
  width: 17.5%;
}
@media (min-width: 768px) {
  .table-type1.separate tr td:nth-child(2), .table-type1.separate tr td:nth-child(3) {
    width: 27.5%;
  }
}
@media (min-width: 1440px) {
  .table-type1.separate tr td:nth-child(2), .table-type1.separate tr td:nth-child(3) {
    width: 25%;
  }
}
.table-type1.separate tr td::after {
  right: 0;
  left: 0;
}
@media (min-width: 768px) {
  .table-type1.separate tr td::after {
    right: 20px;
    left: 20px;
  }
}
@media (min-width: 1024px) {
  .table-type1.separate tr td::after {
    right: 40px;
    left: 40px;
  }
}
@media (min-width: 768px) {
  .table-type1.separate tr td.rnd {
    border-radius: 0 0 12px 12px;
  }
}
.table-type1.separate tr td.bg-transp {
  background-color: transparent;
}
.table-type1.separate tr td .svg-icon {
  width: 16px;
  height: 16px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .table-type1.separate tr td .svg-icon {
    width: 20px;
    height: 20px;
  }
}
.table-type1.separate tr:first-child td {
  padding-top: 20px;
}
@media (min-width: 768px) {
  .table-type1.separate tr:first-child td {
    padding-top: 40px;
  }
}
.table-type1.separate tr:last-child td {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .table-type1.separate tr:last-child td {
    padding-bottom: 40px;
  }
}
.table-type1.separate tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}
.table-type1.separate tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}
@media (min-width: 768px) {
  .table-type1.separate tr:last-child td:last-child {
    border-bottom-right-radius: 0;
  }
}
.table-type1.btm-table tr td {
  padding: 20px !important;
}
.table-type1.btm-table tr td p {
  color: #365edc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 36px;
}
@media (min-width: 1024px) {
  .table-type1.btm-table tr td p {
    font-size: 24px;
  }
}
.table-type1.color-var-col1 tr td:nth-child(1) {
  background-color: #eef2ff;
}
.table-type1.color-var-col1 tr td:nth-child(1)::after {
  border-bottom-color: #e2e7f8;
}
.table-type1.color-var-col2 tr td:nth-child(2) {
  background-color: #eef2ff;
}
.table-type1.color-var-col2 tr td:nth-child(2)::after {
  border-bottom-color: #e2e7f8;
}
.table-type1.color-var-col3 tr td:nth-child(3) {
  background-color: #eef2ff;
}
.table-type1.color-var-col3 tr td:nth-child(3)::after {
  border-bottom-color: #e2e7f8;
}

.table-type2-wrap {
  background-color: #fff;
  padding: 24px 20px 20px 20px;
  border-radius: 20px;
}
@media (min-width: 1024px) {
  .table-type2-wrap {
    padding: 40px 40px 20px 40px;
  }
}
.table-type2-wrap.color-variant {
  background-color: #eef2ff;
}
.table-type2-wrap.color-variant .table-type2 tr td {
  border-bottom-color: #e2e7f8;
}

.table-type2 {
  width: 100%;
}
.table-type2 tr td {
  color: #999999;
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
  padding: 20px 0;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
@media (min-width: 480px) {
  .table-type2 tr td {
    font-size: 16px;
  }
}
.table-type2 tr td:last-child {
  text-align: right;
  color: #333333;
  font-size: 16px;
  padding-left: 6px;
}
@media (min-width: 480px) {
  .table-type2 tr td:last-child {
    font-size: 20px;
  }
}
.table-type2 tr:first-child td {
  border-top: none;
}
.table-type2 tr:last-child td {
  border-bottom: none;
}

.bttn {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  background-color: #365edc;
  padding: 16px 20px;
  border-radius: 16px;
}
.bttn:hover {
  background-color: #2246bf;
}

.ellipsis-btn {
  width: 24px;
  height: 24px;
  color: #fff;
  font-size: 16px;
  background-color: #365edc;
  padding-top: 2px;
  border-radius: 8px;
}

.arr-btn {
  width: 72px;
  height: 40px;
  background-color: #f2f2f2;
  border-radius: 12px;
}
.arr-btn.grey .svg-icon {
  fill: #cccccc;
}
.arr-btn.accent .svg-icon {
  fill: #fff;
}

.close-btn {
  background-color: transparent;
  padding: 5px;
}

.custom-scroll::-webkit-scrollbar {
  width: 4px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 2px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 2px;
}
.custom-scroll.v2::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.3);
}

.hid-scroll {
  scrollbar-width: none;
}
.hid-scroll::-webkit-scrollbar {
  width: 0;
}
.hid-scroll::-webkit-scrollbar-thumb {
  border-radius: 0;
}

.hid-scroll-h::-webkit-scrollbar {
  width: auto;
  height: 0;
}

/* input-placeholder */
input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

textarea::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

textarea:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input::-ms-clear {
  display: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

label {
  display: block;
}

.field-sect-gr .field-sect:first-child {
  margin-top: 24px;
}

.field-sect {
  position: relative;
  margin-top: 24px;
}
.field-sect.w50 {
  width: calc(50% - 6px);
}
.field-sect:first-child {
  margin-top: 0;
}
.field-sect.after-symbol::after {
  content: "₽";
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #365edc;
  font-size: 16px;
  font-weight: 400;
}
.field-sect.after-symbol.v2::after {
  content: "%";
  right: auto;
  left: 52px;
  color: #999999;
}
.field-sect.with-icon .svg-icon {
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 1;
  fill: transparent;
  stroke: #365edc;
}

.input-label {
  color: #365edc;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
}

.field {
  width: 100%;
  height: 48px;
  color: #999999;
  font-size: 16px;
  background-color: #f2f2f2;
  padding: 16px;
  border-radius: 8px;
}
.field.v2 {
  background-color: #fff;
}
.field.sm-field {
  width: 80px;
}
.field.sm-field.pr30 {
  padding-right: 30px;
}
.field::placeholder {
  color: #999999;
}
.field.date {
  padding-left: 42px;
}

.sm-rest-field {
  width: calc(100% - 80px - 12px);
}

.by + .by {
  margin-top: 16px;
}

.form-block {
  color: #999999;
  font-size: 16px;
  background-color: #eef2ff;
  padding: 20px;
  border-radius: 20px;
}
.form-block.v2 {
  background-color: #fff;
  padding: 20px 15px;
}
@media (min-width: 480px) {
  .form-block.v2 {
    padding: 20px;
  }
}
@media (min-width: 1024px) {
  .form-block.v2 {
    padding: 40px;
  }
}

.checks-segm {
  background-color: #fff;
  padding: 16px 20px;
  border-radius: 8px;
}
.checks-segm input {
  display: none;
}
.checks-segm input:checked + label .svg-icon {
  fill: #365edc;
}
.checks-segm label {
  margin-right: 24px;
  cursor: pointer;
}
.checks-segm label:last-child {
  margin-right: 0;
}
.checks-segm .svg-icon {
  width: 16px;
  height: 16px;
  fill: #999999;
  transition: fill 0.2s;
}

.radio-gr input {
  display: none;
}
.radio-gr input:checked + label {
  color: #365edc;
  background-color: #eef2ff;
  border-color: #365edc;
}
.radio-gr label {
  color: #999999;
  background-color: #f2f2f2;
  padding: 15px 11px;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}
@media (min-width: 480px) {
  .radio-gr label {
    padding: 15px;
  }
}
@media (min-width: 768px) {
  .radio-gr label {
    padding: 15px 11px;
  }
}
@media (min-width: 1024px) {
  .radio-gr label {
    padding: 15px;
  }
}

.jq-selectbox {
  width: 100%;
}

.jq-selectbox__select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  color: #999999;
  font-size: 16px;
  background-color: #f2f2f2;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.jq-selectbox__trigger .svg-icon {
  position: relative;
  z-index: 10;
  fill: #365edc;
  transform: rotate(180deg);
}
.jq-selectbox__trigger .svg-icon.mod {
  transform: none;
}

.jq-selectbox__dropdown {
  top: 0 !important;
  z-index: 9;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}
.jq-selectbox__dropdown ul {
  padding: 8px 0;
}
.jq-selectbox__dropdown ul li {
  padding: 8px 16px;
  cursor: pointer;
}
.jq-selectbox__dropdown ul li:hover {
  color: #365edc;
}

.flatpickr-calendar {
  font-size: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}
.flatpickr-calendar::before, .flatpickr-calendar::after {
  content: none;
}

.numInputWrapper {
  display: none !important;
}

.flatpickr-weekdays {
  height: 36px;
  margin-top: 6px;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  color: #365edc;
  font-size: 18px;
  font-weight: 400;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: transparent;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.flatpickr-day {
  color: #999999;
  border-radius: 8px;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed {
  color: #e5e5e5;
}
.flatpickr-day.flatpickr-disabled.prevMonthDay, .flatpickr-day.flatpickr-disabled.nextMonthDay, .flatpickr-day.flatpickr-disabled:hover.prevMonthDay, .flatpickr-day.flatpickr-disabled:hover.nextMonthDay, .flatpickr-day.prevMonthDay.prevMonthDay, .flatpickr-day.prevMonthDay.nextMonthDay, .flatpickr-day.nextMonthDay.prevMonthDay, .flatpickr-day.nextMonthDay.nextMonthDay, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #e5e5e5;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange {
  background-color: #365edc;
  border-color: #365edc;
}
.flatpickr-day.selected.inRange, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover {
  background-color: #365edc;
  border-color: #365edc;
}
.flatpickr-day.today {
  border-color: #cccccc;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  top: 3px;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 30px;
  height: 14px;
  fill: #999999;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #365edc;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 5px;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 5px;
}

.svg-icon {
  display: block;
}

.icon-location {
  width: 13px;
  height: 16px;
}

.icon-phone,
.icon-cross {
  width: 16px;
  height: 16px;
}

.icon-arrow_long_left,
.icon-arrow_long_right {
  width: 34px;
  height: 16px;
}

.icon-telegram,
.icon-vk,
.icon-whatsapp,
.icon-youtube {
  width: 20px;
  height: 20px;
}

.icon-star {
  width: 22px;
  height: 22px;
}

.icon-dash,
.icon-check,
.icon-zoom {
  width: 24px;
  height: 24px;
}

.icon-triangle {
  width: 12px;
  height: 9px;
}

.icon-arrow_flex {
  width: 102px;
  height: 21px;
}

.icon-bracket_left,
.icon-bracket_right {
  width: 8px;
  height: 12px;
}

.icon-quot_left,
.icon-quot_right {
  width: 10px;
  height: 8px;
}

.icon-calendar {
  width: 16px;
  height: 16px;
  stroke-width: 1.59681;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.slick-arrow {
  position: absolute;
  z-index: 5;
  width: 72px;
  height: 48px;
  border-radius: 16px;
}
.slick-arrow:hover {
  background-color: #365edc !important;
}
.slick-arrow:hover .svg-icon {
  fill: #fff;
}
.slick-arrow .svg-icon {
  fill: #cccccc;
}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 5;
  padding: 12px;
  border-radius: 12px;
}
.slick-dots li {
  font-size: 0;
}
.slick-dots li + li {
  margin-left: 8px;
}
.slick-dots li.slick-active button {
  background-color: #365edc;
}
.slick-dots button {
  width: 12px;
  height: 12px;
  text-indent: -9999px;
  border-radius: 4px;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -12px;
}
.tags li {
  color: #365edc;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  background-color: #eef2ff;
  padding: 12px 16px;
  border-radius: 12px;
  margin: 12px 12px 0 0;
}

.main-header {
  padding: 20px 0;
}
.main-header + section {
  padding-top: 20px;
}
.main-header .header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 12px;
  border-radius: 16px;
}
@media (min-width: 768px) {
  .main-header .header-inner {
    padding: 16px 16px 16px 24px;
    border-radius: 24px;
  }
}
.main-header .logo-link-group .logo-link {
  font-size: 16px;
}
@media (min-width: 768px) {
  .main-header .logo-link-group .logo-link {
    font-size: 40px;
  }
}
.main-header .logo-link-group span {
  max-width: 160px;
  font-size: 16px;
}
.main-header .links-group-elem {
  margin-right: 40px;
}
@media (min-width: 768px) {
  .main-header .links-group-elem {
    margin-right: 24px;
  }
}
@media (min-width: 1024px) {
  .main-header .links-group-elem {
    margin-right: 40px;
  }
}
.main-header .links-group-elem:last-child {
  margin-right: 0;
}
.main-header .links-group-link {
  display: flex;
  align-items: center;
  font-size: 12px;
}
@media (min-width: 768px) {
  .main-header .links-group-link {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .main-header .links-group-link + .links-group-link {
    margin-top: 16px;
  }
}
@media (min-width: 1440px) {
  .main-header .links-group-link + .links-group-link {
    margin-top: 0;
    margin-left: 40px;
  }
}
.main-header.v2 .header-inner {
  background-color: transparent;
  padding: 0;
}

.logo-link-group {
  color: #999999;
  font-size: 16px;
}
.logo-link-group .logo-link {
  display: block;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: 500;
  margin-right: 16px;
}
.logo-link-group span {
  display: block;
  max-width: 122px;
  font-size: 12px;
}

.links-group-elem a > .svg-icon,
.sidebar-mov > ul > li a > .svg-icon {
  margin-right: 12px;
  fill: #333333;
}

.sidebar-mov {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 50;
  top: 0;
  right: 100%;
  bottom: 0;
  width: 100%;
  background-color: #fafafa;
  padding: 20px 15px 80px 15px;
  transition: right 0.3s;
}
@media (min-width: 480px) {
  .sidebar-mov {
    width: 360px;
  }
}
.sidebar-mov.mod {
  right: 0;
}
@media (min-width: 480px) {
  .sidebar-mov.mod {
    right: calc(100% - 360px);
  }
}
.sidebar-mov ul {
  font-size: 16px;
  margin-top: 60px;
}
.sidebar-mov ul li + li {
  margin-top: 40px;
}

section {
  padding: 80px 0;
}
section .section-inner > h2,
section .section-inner > .h-wrap > h2 {
  font-size: 24px;
  border-left: 4px solid #365edc;
  padding-left: 20px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  section .section-inner > h2,
  section .section-inner > .h-wrap > h2 {
    font-size: 28px;
  }
}
section .section-inner > .h-wrap {
  margin-bottom: 40px;
}
section .section-inner > .h-wrap > h2 {
  margin-bottom: 0;
}

.article-descr {
  max-height: 100%;
  overflow-y: auto;
  line-height: 1.4;
}
.article-descr h2 {
  margin-bottom: 20px;
}
.article-descr header {
  margin-bottom: 20px;
}
.article-descr header h2 {
  margin-bottom: 0;
}

.estimate-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.estimate-block span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-left: 6px;
}
@media (min-width: 768px) {
  .estimate-block span {
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  .estimate-block span {
    margin-left: 12px;
  }
}

.stars-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stars-block .icon-star {
  width: 16px;
  height: 16px;
  fill: #cccccc;
  margin-right: 4px;
}
@media (min-width: 768px) {
  .stars-block .icon-star {
    padding-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .stars-block .icon-star {
    width: 22px;
    height: 22px;
  }
}
.stars-block .icon-star.active {
  fill: #ffab0a;
}

.slider1 {
  padding-bottom: 88px;
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 480px) {
  .slider1 {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .slider1 {
    padding-bottom: 0;
  }
}
@media (min-width: 480px) {
  .slider1 .slick-list {
    border-radius: 16px;
  }
}
.slider1 .img-wrap {
  height: 280px;
  border-radius: 0;
}
@media (min-width: 480px) {
  .slider1 .img-wrap {
    height: 320px;
  }
}
@media (min-width: 768px) {
  .slider1 .img-wrap {
    height: 480px;
  }
}
@media (min-width: 1024px) {
  .slider1 .img-wrap {
    height: 720px;
  }
}
.slider1 article {
  position: relative;
}
.slider1 .article-descr {
  position: absolute;
  z-index: 2;
  right: 20px;
  bottom: 20px;
  left: 20px;
  max-height: 130px;
  color: #fff;
  padding-right: 5px;
  margin-right: -5px;
}
@media (min-width: 480px) {
  .slider1 .article-descr {
    bottom: 30px;
    max-height: 155px;
  }
}
@media (min-width: 768px) {
  .slider1 .article-descr {
    right: 40px;
    bottom: 40px;
    left: 40px;
    max-height: calc(100% - 80px);
  }
}
@media (min-width: 1024px) {
  .slider1 .article-descr {
    bottom: 116px;
    max-height: 180px;
  }
}
.slider1 .article-descr p {
  font-size: 16px;
}
@media (min-width: 768px) {
  .slider1 .article-descr p {
    font-size: 18px;
  }
}
@media (min-width: 1024px) {
  .slider1 .article-descr p {
    font-size: 20px;
  }
}
.slider1 .slick-arrow {
  bottom: 0;
  background-color: #f2f2f2;
}
@media (min-width: 1024px) {
  .slider1 .slick-arrow {
    top: 50%;
    bottom: auto;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
    transform: translateY(-50%);
  }
}
.slider1 .slick-arrow:hover {
  background-color: #365edc;
}
.slider1 .slick-arrow:hover .svg-icon {
  fill: #fff;
}
.slider1 .slick-arrow.slick-prev {
  left: 15px;
}
@media (min-width: 480px) {
  .slider1 .slick-arrow.slick-prev {
    left: 0;
  }
}
@media (min-width: 1024px) {
  .slider1 .slick-arrow.slick-prev {
    left: 40px;
  }
}
.slider1 .slick-arrow.slick-next {
  right: 15px;
}
@media (min-width: 480px) {
  .slider1 .slick-arrow.slick-next {
    right: 0;
  }
}
@media (min-width: 1024px) {
  .slider1 .slick-arrow.slick-next {
    right: 40px;
  }
}
.slider1 .slick-arrow .svg-icon {
  fill: #cccccc;
}
@media (min-width: 1024px) {
  .slider1 .slick-arrow .svg-icon {
    fill: #fff;
  }
}
.slider1 .slick-dots {
  bottom: 6px;
  left: 50%;
  background-color: #f2f2f2;
  transform: translateX(-50%);
}
@media (min-width: 1024px) {
  .slider1 .slick-dots {
    bottom: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
  }
}
.slider1 .slick-dots button {
  background-color: #cccccc;
}
@media (min-width: 1024px) {
  .slider1 .slick-dots button {
    background-color: #fff;
  }
}

.slider1a .img-wrap {
  height: 280px;
}
@media (min-width: 768px) {
  .slider1a .img-wrap {
    height: 420px;
  }
}
@media (min-width: 1024px) {
  .slider1a .img-wrap {
    height: 600px;
  }
}
@media (min-width: 1440px) {
  .slider1a .img-wrap {
    height: 720px;
  }
}
.slider1a .slick-arrow {
  top: 50%;
  background-color: #f2f2f2;
}
@media (min-width: 1024px) {
  .slider1a .slick-arrow {
    top: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
    transform: translateY(-50%);
  }
}
.slider1a .slick-arrow:hover {
  background-color: #365edc;
}
.slider1a .slick-arrow:hover .svg-icon {
  fill: #fff;
}
.slider1a .slick-arrow.slick-prev {
  left: 40px;
}
.slider1a .slick-arrow.slick-next {
  right: 40px;
}
.slider1a .slick-arrow .svg-icon {
  fill: #cccccc;
}
@media (min-width: 1024px) {
  .slider1a .slick-arrow .svg-icon {
    fill: #fff;
  }
}
.slider1a .slick-dots {
  left: 50%;
  bottom: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .slider1a .slick-dots {
    bottom: 40px;
  }
}
.slider1a .slick-dots button {
  background-color: #fff;
}

.sliders_23 {
  overflow: hidden;
  background-color: #fff;
  border-radius: 16px;
}
@media (min-width: 1440px) {
  .sliders_23 {
    display: flex;
  }
}

@media (min-width: 1440px) {
  .slider2 {
    width: 620px;
  }
}
.slider2 .slide {
  height: 336px;
  border-radius: 0;
}
@media (min-width: 768px) {
  .slider2 .slide {
    height: 480px;
  }
}
@media (min-width: 1024px) {
  .slider2 .slide {
    height: 600px;
  }
}
@media (min-width: 1440px) {
  .slider2 .slide {
    height: 720px;
  }
}

.slider3-wrap {
  position: relative;
}
@media (min-width: 1440px) {
  .slider3-wrap {
    width: 660px;
  }
}
.slider3-wrap .pages {
  position: absolute;
  top: 56px;
  left: 50%;
  color: #cccccc;
  font-weight: 700;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .slider3-wrap .pages {
    top: 54px;
  }
}
@media (min-width: 1024px) {
  .slider3-wrap .pages {
    top: 52px;
  }
}
@media (min-width: 1440px) {
  .slider3-wrap .pages {
    top: auto;
    bottom: 52px;
  }
}

.slider3 .slide {
  padding: 108px 15px 20px 15px;
}
@media (min-width: 480px) {
  .slider3 .slide {
    padding: 120px 20px 30px 20px;
  }
}
@media (min-width: 768px) {
  .slider3 .slide {
    padding: 120px 40px 40px 40px;
  }
}
@media (min-width: 1440px) {
  .slider3 .slide {
    height: 720px;
    padding: 40px 40px 120px 40px;
  }
}
.slider3 .article-descr p {
  color: #999999;
}
.slider3 .slick-arrow {
  top: 40px;
  background-color: #f2f2f2;
}
@media (min-width: 1440px) {
  .slider3 .slick-arrow {
    top: auto;
    bottom: 40px;
  }
}
.slider3 .slick-arrow.slick-prev {
  left: 20px;
}
@media (min-width: 768px) {
  .slider3 .slick-arrow.slick-prev {
    left: 40px;
  }
}
.slider3 .slick-arrow.slick-next {
  right: 20px;
}
@media (min-width: 768px) {
  .slider3 .slick-arrow.slick-next {
    right: 40px;
  }
}

@media (min-width: 1024px) {
  .sliders_45 {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .slider4 {
    width: 420px;
  }
}
@media (min-width: 1440px) {
  .slider4 {
    width: 620px;
  }
}
.slider4 .slide {
  position: relative;
  height: 480px;
}
@media (min-width: 1440px) {
  .slider4 .slide {
    height: 700px;
  }
}
.slider4 .slide .img-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: opacity 0.3s;
}
.slider4 .slide .img-wrap.on-top {
  z-index: 2;
}
.slider4 .slide .img-wrap.hid {
  opacity: 0;
}
.slider4 .slide .switch-btns {
  display: flex;
  position: absolute;
  bottom: 72px;
  left: 50%;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  padding: 4px;
  border: 1px solid #365edc;
  border-radius: 16px;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .slider4 .slide .switch-btns {
    bottom: 40px;
  }
}
.slider4 .slide .switch-btns button {
  width: 100px;
  border-radius: 12px;
}
.slider4 .slide .switch-btns button.non-active {
  background-color: transparent;
}
.slider4 .slick-dots {
  display: flex !important;
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .slider4 .slick-dots {
    display: none !important;
  }
}
.slider4 .slick-dots button {
  background-color: #fff;
}

@media (min-width: 1024px) {
  .slider5 {
    width: 524px;
  }
}
@media (min-width: 1440px) {
  .slider5 {
    width: 660px;
  }
}
.slider5 .slide {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
@media (min-width: 1024px) {
  .slider5 .slide {
    height: 480px;
    padding: 40px 0 40px 40px;
  }
}
@media (min-width: 1440px) {
  .slider5 .slide {
    height: 700px;
    padding: 40px 0 40px 60px;
  }
}
.slider5 .article-descr h2 {
  text-transform: uppercase;
}
.slider5 .article-descr footer {
  margin-top: 40px;
}
.slider5 .article-descr footer button {
  line-height: 1;
}
.slider5 .article-descr footer button .svg-icon {
  fill: #fff;
  margin-left: 20px;
}

.for-slider5-arrows {
  display: flex;
}
.for-slider5-arrows .slick-arrow {
  position: static;
  background-color: #f2f2f2;
  margin-left: 16px;
}

.slider6 {
  padding-bottom: 68px;
  margin-top: -20px;
}
.slider6 .slide {
  padding-top: 20px;
  padding-bottom: 20px;
}
.slider6 .slide:hover .dig-segm span {
  color: #fff;
  background-color: #365edc;
}
@media (min-width: 768px) {
  .slider6 .slide .slide-inner {
    display: flex;
    align-items: center;
  }
}
.slider6 .slide .dig-segm {
  flex-shrink: 0;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .slider6 .slide .dig-segm {
    width: 100px;
    padding-bottom: 0;
  }
}
@media (min-width: 1440px) {
  .slider6 .slide .dig-segm {
    width: 160px;
  }
}
.slider6 .slide .dig-segm span {
  width: 60px;
  height: 60px;
  color: #cccccc;
  font-size: 24px;
  font-weight: 600;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin: 0 auto;
  transition: background-color 0.3s;
}
@media (min-width: 768px) {
  .slider6 .slide .dig-segm span {
    margin: 0;
  }
}
.slider6 .slide .article-descr {
  flex-grow: 1;
  align-items: center;
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 20px;
}
@media (min-width: 480px) {
  .slider6 .slide .article-descr {
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .slider6 .slide .article-descr {
    display: flex;
    padding: 40px;
  }
}
.slider6 .slide .article-descr .img-wrap {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin: 0 auto 20px auto;
}
@media (min-width: 768px) {
  .slider6 .slide .article-descr .img-wrap {
    width: 160px;
    height: 160px;
    margin: 0;
  }
}
.slider6 .slide .article-descr .descr-wrap {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .slider6 .slide .article-descr .descr-wrap {
    padding-left: 40px;
  }
}
.slider6 .slide .article-descr header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .slider6 .slide .article-descr header {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
.slider6 .slide .article-descr header h2 {
  font-size: 20px;
}
@media (min-width: 768px) {
  .slider6 .slide .article-descr header h2 {
    font-size: 24px;
  }
}
.slider6 .slide .article-descr header .estimate-block {
  width: 100%;
}
@media (min-width: 768px) {
  .slider6 .slide .article-descr header .estimate-block {
    width: auto;
  }
}
.slider6 .slick-arrow {
  position: absolute;
  bottom: 0;
  z-index: 5;
  background-color: #f2f2f2;
}
.slider6 .slick-prev {
  left: 0;
}
.slider6 .slick-next {
  right: 0;
}

@media (min-width: 768px) {
  .slider7 {
    margin-right: -20px;
    margin-left: -20px;
  }
}
@media (min-width: 768px) {
  .slider7 .slide {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.slider7 .img-wrap {
  height: 480px;
}
.slider7 .img-wrap .cover {
  z-index: 1;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%);
}
.slider7 .img-wrap .article-cont {
  display: flex;
  align-items: flex-end;
  z-index: 2;
  color: #fff;
  font-size: 16px;
  padding: 40px 15px;
  transition: background-color 0.3s, backdrop-filter 0.3s;
}
@media (min-width: 480px) {
  .slider7 .img-wrap .article-cont {
    padding: 40px;
  }
}
.slider7 .img-wrap .article-cont .descr-block {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}
.slider7 .img-wrap .article-cont .descr-block-elem {
  margin-bottom: 40px;
}
.slider7 .img-wrap .article-cont .sub-h {
  color: #cccccc;
  margin-bottom: 12px;
}
.slider7 .img-wrap .article-cont .like-h {
  font-size: 24px;
}
.slider7 .img-wrap:hover .article-cont {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
}
.slider7 .img-wrap:hover .article-cont .descr-block {
  visibility: visible;
  opacity: 1;
}

.fz0 .slick-slide > div {
  font-size: 0;
}

.slider6 .slick-dots,
.for-slider-arrows .slick-dots {
  position: absolute;
  bottom: 6px;
  left: 50%;
  z-index: 5;
  background-color: #f2f2f2;
  transform: translateX(-50%);
}
.slider6 .slick-dots button,
.for-slider-arrows .slick-dots button {
  background-color: #cccccc;
}

.for-slider-arrows {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.for-slider-arrows .slick-arrow {
  position: static;
  background-color: #f2f2f2;
}
@media (min-width: 768px) {
  .for-slider-arrows .slick-arrow {
    margin-left: 16px;
  }
}
.for-slider-arrows .slick-arrow:first-child {
  margin-left: 0;
}
.for-slider-arrows .slick-dots {
  display: flex !important;
}
@media (min-width: 768px) {
  .for-slider-arrows .slick-dots {
    display: none !important;
  }
}

.for-slider-modal-arrows .slick-dots,
.for-slider12-arrows .slick-dots,
.for-slider14-arrows .slick-dots,
.for-slider17-arrows .slick-dots,
.for-slider18-arrows .slick-dots {
  display: flex !important;
}

.slider8,
.slider9 {
  margin: 0 -15px;
}
@media (min-width: 480px) {
  .slider8,
  .slider9 {
    margin: -20px;
  }
}
.slider8 .slick-slide > div,
.slider9 .slick-slide > div {
  display: flex !important;
}
.slider8 .slide,
.slider9 .slide {
  padding: 0 15px;
}
@media (min-width: 480px) {
  .slider8 .slide,
  .slider9 .slide {
    padding: 20px;
  }
}
.slider8 .slide .slide-link,
.slider8 .slide .slide-segm,
.slider9 .slide .slide-link,
.slider9 .slide .slide-segm {
  display: block;
  cursor: pointer;
}
.slider8 .slide .slide-link:hover .slide-title,
.slider8 .slide .slide-segm:hover .slide-title,
.slider9 .slide .slide-link:hover .slide-title,
.slider9 .slide .slide-segm:hover .slide-title {
  text-decoration-color: #333333;
}
.slider8 .slide .slide-link > span,
.slider8 .slide .slide-segm > span,
.slider9 .slide .slide-link > span,
.slider9 .slide .slide-segm > span {
  display: block;
}
.slider8 .slide .img-wrap,
.slider9 .slide .img-wrap {
  height: 260px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
}
.slider8 .slide .slide-title,
.slider9 .slide .slide-title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: transparent;
  text-underline-offset: 0.15em;
  text-decoration-thickness: 2px;
  margin-top: 20px;
  transition: text-decoration-color 0.2s;
}
.slider8 .slide .slide-title button,
.slider9 .slide .slide-title button {
  font-weight: 700;
}
.slider8 .slide .price,
.slider9 .slide .price {
  display: inline-block !important;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  background-color: #365edc;
  padding: 8px;
  border-radius: 8px;
  margin-top: 20px;
}
.slider8 .modal-cont,
.slider9 .modal-cont {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vw;
  top: -10000px;
  left: -10000px;
  visibility: hidden;
  opacity: 0;
}

.slider9 {
  margin: 0 -15px;
}
@media (min-width: 480px) {
  .slider9 {
    margin: -20px;
  }
}
@media (min-width: 1024px) {
  .slider9 {
    margin: -30px -40px;
  }
}
.slider9 .slide {
  padding: 0 15px;
}
@media (min-width: 480px) {
  .slider9 .slide {
    padding: 20px;
  }
}
@media (min-width: 1024px) {
  .slider9 .slide {
    padding: 30px 40px;
  }
}

.slider10-thumbs {
  margin: 20px -6px 0 -6px;
}
@media (min-width: 768px) {
  .slider10-thumbs {
    margin: 20px -8px 0 -8px;
  }
}
@media (min-width: 1440px) {
  .slider10-thumbs {
    margin: -10px 0;
  }
}
.slider10-thumbs .slick-current .thumb {
  border-color: #365edc;
}
.slider10-thumbs .slide {
  padding: 0 8px;
}
@media (min-width: 1440px) {
  .slider10-thumbs .slide {
    padding: 10px 0;
  }
}
.slider10-thumbs .thumb {
  width: 100%;
  height: 100px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: border-color 0.2s;
}
@media (min-width: 1440px) {
  .slider10-thumbs .thumb {
    width: 100%;
    height: 120px;
  }
}
.slider10-thumbs .thumb .img-wrap {
  height: 100%;
}

.slider11,
.slider16 {
  margin: 0 -15px;
}
@media (min-width: 768px) {
  .slider11,
  .slider16 {
    margin: -20px;
  }
}
.slider11 .slide,
.slider16 .slide {
  padding: 0 15px;
}
@media (min-width: 768px) {
  .slider11 .slide,
  .slider16 .slide {
    padding: 20px;
  }
}
.slider11 .slide .img-wrap,
.slider16 .slide .img-wrap {
  display: block;
  overflow: hidden;
  height: 345px;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .slider11 .slide .img-wrap,
  .slider16 .slide .img-wrap {
    width: 100%;
    height: 290px;
  }
}

.slider12 .slide,
.slider17 .slide {
  padding: 0 15px;
}
@media (min-width: 768px) {
  .slider12 .slide,
  .slider17 .slide {
    padding: 0 20px;
  }
}
.slider12 .slide .img-wrap,
.slider17 .slide .img-wrap {
  display: block;
  overflow: hidden;
  height: 345px;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .slider12 .slide .img-wrap,
  .slider17 .slide .img-wrap {
    height: 512px;
  }
}

.slider13 {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 480px) {
  .slider13 {
    margin-right: 0;
    margin-left: 0;
  }
}
.slider13 .slide {
  position: relative;
  height: 212px;
  overflow: hidden;
}
@media (min-width: 480px) {
  .slider13 .slide {
    border-radius: 12px;
  }
}
@media (min-width: 768px) {
  .slider13 .slide {
    height: 400px;
  }
}
@media (min-width: 1024px) {
  .slider13 .slide {
    height: 600px;
  }
}
@media (min-width: 1440px) {
  .slider13 .slide {
    height: 800px;
  }
}
.slider13 .slide .title {
  position: absolute;
  top: 12px;
  z-index: 5;
  color: #365edc;
  font-size: 12px;
  font-weight: 700;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .slider13 .slide .title {
    top: 24px;
    font-size: 16px;
    padding: 16px 20px;
    border-radius: 16px;
  }
}
@media (min-width: 1024px) {
  .slider13 .slide .title {
    top: 40px;
    font-size: 20px;
  }
}
.slider13 .slide .title.left {
  left: 25%;
  transform: translateX(-50%);
}
.slider13 .slide .title.right {
  right: 25%;
  transform: translateX(50%);
}
.slider13 .slide .over-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-left: 18px;
  width: calc(50% + 18px);
}
.slider13 .slide .over-wrap .over-wrap-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.slider13 .slide .over-wrap .img-wrap-esp {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.slider13 .slide .over-wrap .over-wrap-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 2px;
  background-color: #365edc;
  cursor: e-resize;
}
.slider13 .slide .over-wrap .arrows-gr {
  position: absolute;
  top: 50%;
  left: 1px;
  z-index: 1;
  display: flex;
  transform: translateY(-50%);
}
.slider13 .slide .over-wrap .arrows-gr .wwu-left-btn,
.slider13 .slide .over-wrap .arrows-gr .wwu-right-btn {
  width: 18px;
  height: 52px;
  background-color: #365edc;
}
.slider13 .slide .over-wrap .arrows-gr .wwu-left-btn .svg-icon,
.slider13 .slide .over-wrap .arrows-gr .wwu-right-btn .svg-icon {
  fill: #fff;
}
.slider13 .slide .over-wrap .arrows-gr .wwu-left-btn {
  border-radius: 12px 0 0 12px;
}
.slider13 .slide .over-wrap .arrows-gr .wwu-right-btn {
  border-radius: 0 12px 12px 0;
}

.slider14 .video-wrap {
  height: 196px;
  overflow: hidden;
  background-color: #f2f2f2;
  border-radius: 8px;
}
@media (min-width: 400px) {
  .slider14 .video-wrap {
    height: 250px;
  }
}
@media (min-width: 768px) {
  .slider14 .video-wrap {
    height: 416px;
  }
}
@media (min-width: 1024px) {
  .slider14 .video-wrap {
    height: 540px;
  }
}
@media (min-width: 1440px) {
  .slider14 .video-wrap {
    height: 720px;
  }
}
.slider14 .video-wrap video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider15 {
  display: none;
  margin-right: -10px;
  margin-left: -10px;
}
@media (min-width: 768px) {
  .slider15 {
    display: block;
  }
}
@media (min-width: 1440px) {
  .slider15 {
    margin-right: -20px;
    margin-left: -20px;
  }
}
.slider15 .slide {
  padding: 40px 10px;
}
@media (min-width: 1440px) {
  .slider15 .slide {
    padding: 40px 20px;
  }
}
.slider15 .slide .img-wrap {
  height: 120px;
  overflow: hidden;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.2s;
}
@media (min-width: 1024px) {
  .slider15 .slide .img-wrap {
    height: 160px;
  }
}
@media (min-width: 1440px) {
  .slider15 .slide .img-wrap {
    height: 225px;
  }
}
.slider15 .slick-current .slide .img-wrap {
  border-color: #365edc;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
}
@media (min-width: 1440px) {
  .slider15 .slick-current .slide .img-wrap {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.4);
  }
}

.slider17 {
  width: 280px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 480px) {
  .slider17 {
    width: auto;
    margin-right: -20px;
    margin-left: -20px;
  }
}
.slider17 .slide .img-wrap {
  height: 360px;
}
@media (min-width: 480px) {
  .slider17 .slide .img-wrap {
    height: 320px;
  }
}
@media (min-width: 768px) {
  .slider17 .slide .img-wrap {
    height: 480px;
  }
}
@media (min-width: 1024px) {
  .slider17 .slide .img-wrap {
    height: 620px;
  }
}
@media (min-width: 1440px) {
  .slider17 .slide .img-wrap {
    height: 560px;
  }
}

.slider18 {
  overflow: hidden;
  border-radius: 12px;
}
.slider18 .img-wrap {
  height: 345px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .slider18 .img-wrap {
    height: 364px;
  }
}
@media (min-width: 1024px) {
  .slider18 .img-wrap {
    height: 532px;
  }
}
@media (min-width: 1440px) {
  .slider18 .img-wrap {
    height: 400px;
  }
}

.slider19 {
  display: none;
  margin: 40px -10px 0 -10px;
}
@media (min-width: 768px) {
  .slider19 {
    display: block;
  }
}
.slider19 .slide {
  padding: 0 10px;
}
.slider19 .img-wrap {
  height: 120px;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: border-color 0.2s;
}
.slider19 .slick-current .img-wrap {
  border-color: #365edc;
}

@media (min-width: 1440px) {
  .slider20 .slick-list,
  .slider20 .slick-track,
  .slider20 .slick-slide > div,
  .slider20 .slide {
    height: 100%;
  }
}
@media (min-width: 1440px) {
  .slider20 .slide {
    display: flex !important;
    flex-direction: column;
  }
}

.for-slider21-arrows .slick-arrow {
  margin-left: 16px;
}

.slider-modal-nest {
  width: 100vw;
  height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.slider-modal .slick-list,
.no-slider .slick-list {
  border-radius: 16px;
}
.slider-modal .slide,
.no-slider .slide {
  overflow: hidden;
  background-color: #fff;
}
.slider-modal .slide .img-wrap,
.no-slider .slide .img-wrap {
  height: 600px;
  border-radius: 0;
}

.no-slider .slide {
  overflow: hidden;
  border-radius: 12px;
}

.inimg-slider .slick-list {
  border-radius: 8px;
}
.inimg-slider .slide {
  padding: 0;
}
.inimg-slider .slide .img-wrap {
  border-radius: 0;
}
.inimg-slider .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 1;
  padding: 0;
  transform: translateX(-50%);
}
.inimg-slider .slick-dots button {
  width: 8px;
  height: 8px;
  background-color: #cccccc;
}

.for-zoom {
  position: relative;
}
.for-zoom .button-zoom {
  position: absolute;
  top: 34px;
  right: 34px;
  z-index: 10;
  padding: 6px;
}
.for-zoom .icon-zoom {
  fill: #fff;
}

.cards .col.hdn {
  display: none;
}
.cards article {
  position: relative;
  overflow: hidden;
  height: 480px;
  color: #fff;
  border-radius: 8px;
  transition: height 0.2s;
}
.cards article .img-wrap {
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: 1;
}
.cards article .article-descr {
  position: relative;
  z-index: 2;
  overflow: hidden;
  height: 100%;
  padding: 20px;
  transition: background-color 0.3s, backdrop-filter 0.3s;
}
@media (min-width: 480px) {
  .cards article .article-descr {
    padding: 40px;
  }
}
.cards article .article-descr header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  height: 40px;
}
@media (min-width: 480px) {
  .cards article .article-descr header {
    height: 48px;
  }
}
.cards article .article-descr header a {
  display: block;
  padding: 0 0 3px 6px;
}
.cards article .article-descr header a:hover .svg-icon {
  fill: #abbcfa;
}
.cards article .article-descr .svg-icon {
  fill: #fff;
  transition: fill 0.2s;
}
.cards article .article-descr p {
  color: #cccccc;
  font-size: 16px;
  vertical-align: middle;
}
.cards article .article-descr p a {
  color: #fff;
}
.cards article .article-descr p a .svg-icon {
  display: inline-block;
  fill: #fff;
  margin: 0 0 2px 6px;
}
.cards article .article-descr p a:hover {
  color: #abbcfa;
}
.cards article .article-descr p a:hover .svg-icon {
  fill: #abbcfa;
}
.cards article .article-descr-inner {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.cards article .move-block {
  position: absolute;
  top: calc(100% - 40px);
  right: 0;
  left: 0;
  height: 100%;
  transition: top 0.5s;
}
@media (min-width: 480px) {
  .cards article .move-block {
    top: calc(100% - 48px);
  }
}
.cards article:hover .article-descr {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
}
.cards article:hover .move-block {
  top: 0;
}

.map {
  position: relative;
  overflow: hidden;
  height: 760px;
  background-color: #f2f2f2;
  border-radius: 16px;
}
.map .inner-map {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
}
.map .contacts-block {
  position: absolute;
  top: 70px;
  right: 20px;
  left: 20px;
  z-index: 10;
  text-align: center;
  background-color: #fff;
  padding: 60px 40px;
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}
@media (min-width: 480px) {
  .map .contacts-block {
    right: 40px;
    left: 40px;
  }
}
@media (min-width: 768px) {
  .map .contacts-block {
    right: auto;
    width: 336px;
    padding: 60px;
    visibility: visible;
    opacity: 1;
  }
}
.map .contacts-block.mod {
  visibility: visible;
  opacity: 1;
}
.map .contacts-block header {
  margin-bottom: 40px;
}
.map .contacts-block header h2 {
  color: #365edc;
  font-size: 28px;
}
.map .contacts-block .contacts {
  font-size: 20px;
  font-weight: 500;
}
.map .contacts-block .contacts > li + li {
  margin-top: 32px;
}
.map .contacts-block .contacts .label {
  display: block;
  color: #cccccc;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}
.map .contacts-block .soc-links {
  margin-top: 16px;
}
.map .contacts-block .soc-links li + li {
  margin-left: 32px;
}
.map .contacts-block .soc-links .svg-icon {
  fill: #333333;
}
.map .contacts-block .close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 5;
}
.map .contacts-block .close-btn .svg-icon {
  fill: #cccccc;
}
.map .picts-wrap {
  position: absolute;
  top: 580px;
  left: 0;
  z-index: 10;
  width: 100%;
  overflow-x: auto;
  padding: 20px;
}
@media (min-width: 480px) {
  .map .picts-wrap {
    padding-left: 40px;
  }
}
@media (min-width: 768px) {
  .map .picts-wrap {
    width: auto;
    padding-right: 0;
  }
}
.map .picts-wrap .picts {
  display: flex;
}
.map .picts-wrap .picts li {
  flex-shrink: 0;
  width: 160px;
}
.map .picts-wrap .picts li + li {
  margin-left: 16px;
}
.map .picts-wrap .picts .img-wrap {
  display: block;
  width: 100%;
  height: 90px;
  position: relative;
  border-radius: 16px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s;
}
.map .picts-wrap .picts .img-wrap:hover {
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.4);
}
.map .picts-wrap .picts .img-wrap .img-count-in-modal {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  width: 26px;
  height: 24px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #365edc;
  border-radius: 8px;
}
.map .picts-wrap .picts::after {
  content: "";
  display: inline-block;
  width: 20px;
  flex-shrink: 0;
}
@media (min-width: 480px) {
  .map .picts-wrap .picts::after {
    width: 40px;
  }
}
@media (min-width: 768px) {
  .map .picts-wrap .picts::after {
    width: 0;
  }
}
.map .btn-wrap {
  position: absolute;
  top: 532px;
  right: 0;
  left: 0;
  z-index: 5;
  text-align: center;
}

@media (min-width: 768px) {
  .with-left-image {
    display: flex;
    align-items: flex-start;
  }
}
@media (min-width: 1024px) {
  .with-left-image {
    align-items: center;
  }
}
.with-left-image h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  .with-left-image h2 {
    font-size: 28px;
    margin-bottom: 40px;
  }
}
.with-left-image .img-wrap {
  height: 480px;
  margin-bottom: 60px;
}
@media (min-width: 480px) {
  .with-left-image .img-wrap {
    height: 600px;
  }
}
@media (min-width: 768px) {
  .with-left-image .img-wrap {
    width: 300px;
    height: 420px;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .with-left-image .img-wrap {
    width: 420px;
    height: 600px;
  }
}
@media (min-width: 768px) {
  .with-left-image .section-descr {
    width: calc(100% - 300px);
    padding-left: 40px;
  }
}
@media (min-width: 1024px) {
  .with-left-image .section-descr {
    width: calc(100% - 420px);
  }
}
@media (min-width: 1440px) {
  .with-left-image .section-descr {
    padding-left: 60px;
  }
}

.tariff-choose .icon-check {
  fill: #365edc;
}
.tariff-choose .icon-dash {
  fill: #cccccc;
}
.tariff-choose .under-table-block .utb-elem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  padding: 15px;
  border-radius: 20px;
  margin-top: 20px;
}
.tariff-choose .under-table-block .utb-elem .heading-p {
  color: #365edc;
  font-size: 20px;
  margin-bottom: 8px;
}
.tariff-choose .under-table-block .utb-elem button {
  min-width: 140px;
}

.price-list .table-type2-wrap h2 {
  color: #365edc;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  .price-list .table-type2-wrap h2 {
    font-size: 28px;
  }
}

.catalog .cat-menu {
  color: #365edc;
  font-size: 20px;
}
.catalog .cat-menu li + li {
  margin-top: 32px;
}
.catalog .cat-menu > li {
  transition: margin-top 0.5s;
}
.catalog .cat-menu > li > span {
  display: flex;
  align-items: center;
}
.catalog .cat-menu > li > span button {
  visibility: hidden;
  margin-right: 12px;
}
.catalog .cat-menu > li > span button .svg-icon {
  fill: #365edc;
  transform: rotate(180deg);
}
.catalog .cat-menu > li > .ul-wrap {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.5s;
}
.catalog .cat-menu > li > .ul-wrap > ul {
  padding-left: 40px;
}
.catalog .cat-menu > li > .ul-wrap.ofya {
  overflow-y: auto;
}
.catalog .cat-menu > li:has(ul) + li {
  margin-top: 0;
}
.catalog .cat-menu > li:has(ul) > span {
  padding-bottom: 32px;
}
.catalog .cat-menu > li:has(ul) > span button {
  visibility: visible;
}
.catalog .cat-menu > li.mod > span button .svg-icon {
  transform: none;
}
.catalog .cat-menu > li.mod > .ul-wrap {
  max-height: 390px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: -3px;
  margin-bottom: -3px;
}
.catalog .cat-menu > li.mod2 {
  margin-top: 32px;
}

.prod-card {
  background-color: #fff;
  padding: 40px 15px;
  margin: 0 -15px;
}
@media (min-width: 480px) {
  .prod-card {
    padding: 40px 20px;
    border-radius: 12px;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .prod-card {
    padding: 40px;
  }
}
@media (min-width: 1024px) {
  .prod-card {
    background-color: transparent;
    padding: 0;
  }
}
.prod-card .row-c {
  margin: 0 -10px;
}
.prod-card .row-c > .col {
  flex: 0 0 auto;
  padding: 0 10px;
}
.prod-card .thumbs-wrap {
  order: 1;
}
@media (min-width: 768px) {
  .prod-card .thumbs-wrap {
    width: 410px;
  }
}
@media (min-width: 1024px) {
  .prod-card .thumbs-wrap {
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .prod-card .thumbs-wrap {
    width: 140px;
    order: 0;
  }
}
.prod-card .slider10 {
  order: 0;
}
@media (min-width: 768px) {
  .prod-card .slider10 {
    width: 410px;
  }
}
@media (min-width: 1024px) {
  .prod-card .slider10 {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .prod-card .slider10 {
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .prod-card .slider10 {
    width: 500px;
    order: 1;
  }
}
.prod-card .slider10 .img-wrap,
.prod-card .prod-descr {
  overflow: hidden;
  border-radius: 12px;
}
.prod-card .slider10 .img-wrap {
  height: 456px;
}
@media (min-width: 1440px) {
  .prod-card .slider10 .img-wrap {
    height: 640px;
  }
}
.prod-card header {
  margin-bottom: 32px;
}
@media (min-width: 1440px) {
  .prod-card header {
    margin-bottom: 40px;
  }
}
.prod-card header h2 {
  color: #333333;
  font-size: 28px;
  margin-bottom: 16px;
}
.prod-card header .price-block {
  display: flex;
  align-items: center;
}
.prod-card header .price-block .price {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  background-color: #365edc;
  padding: 12px;
  border-radius: 12px;
  margin-right: 16px;
}
.prod-card header .price-block .old-price {
  color: #cccccc;
  font-size: 20px;
  font-weight: 600;
  text-decoration: line-through;
}
.prod-card header.v2 h2 {
  font-size: 24px;
  margin-bottom: 0;
}
.prod-card .prod-descr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
@media (min-width: 1024px) {
  .prod-card .prod-descr {
    height: 576px;
    padding: 40px;
  }
}
@media (min-width: 1440px) {
  .prod-card .prod-descr {
    height: 640px;
  }
}
.prod-card .prod-descr header,
.prod-card .prod-descr footer {
  flex-shrink: 0;
}
.prod-card .prod-descr footer {
  margin-top: 32px;
}
@media (min-width: 1440px) {
  .prod-card .prod-descr footer {
    margin-top: 40px;
  }
}
.prod-card .prod-descr footer button {
  min-width: 150px;
}
.prod-card .prod-descr .descr-cont {
  flex-grow: 1;
  font-size: 16px;
  line-height: 1.4;
}
@media (min-width: 1024px) {
  .prod-card .prod-descr .descr-cont {
    overflow-y: auto;
    padding-right: 6px;
    margin-right: -6px;
  }
}
.prod-card .prod-descr .descr-cont * + p {
  margin-top: 24px;
}
.prod-card .prod-descr .descr-cont ul {
  font-weight: 400;
  line-height: 1;
}
.prod-card .prod-descr .descr-cont ul li {
  display: flex;
}
.prod-card .prod-descr .descr-cont ul li + li {
  margin-top: 20px;
}
.prod-card .prod-descr .descr-cont ul li span {
  flex-shrink: 0;
}
.prod-card .prod-descr .descr-cont ul li span:first-child {
  order: 0;
  color: #cccccc;
}
.prod-card .prod-descr .descr-cont ul li span:last-child {
  order: 2;
  font-weight: 500;
}
.prod-card .prod-descr .descr-cont ul li::after {
  content: "";
  order: 1;
  display: block;
  flex-grow: 1;
  height: 16px;
  border-bottom: 1px dashed #cccccc;
  margin: 0 6px;
}
.prod-card .prod-descr .descr-cont ul.v2 {
  background-color: #eef2ff;
  padding: 20px;
  border-radius: 8px;
}
.prod-card .prod-descr .descr-cont ul.v2 li span:first-child {
  color: #333333;
}
.prod-card .prod-descr .descr-cont ul.v2 li span:last-child {
  color: #365edc;
}
.prod-card .prod-descr .descr-cont ul.v2 li::after {
  border-bottom-color: #333333;
}

.main-section {
  position: relative;
  height: 280px;
  padding: 20px 15px;
  margin: 0 -15px;
}
@media (min-width: 480px) {
  .main-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 480px;
    padding: 20px;
    border-radius: 16px;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .main-section {
    height: 600px;
  }
}
@media (min-width: 1024px) {
  .main-section {
    height: 800px;
  }
}
@media (min-width: 1440px) {
  .main-section {
    height: 720px;
    padding: 40px;
  }
}
.main-section .section-inner {
  max-width: 840px;
  color: #fff;
  text-align: center;
  margin-top: 40px;
}
.main-section .section-inner h1 {
  font-size: 32px;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  padding: 6px 16px;
  border-radius: 20px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .main-section .section-inner h1 {
    font-size: 64px;
  }
}
@media (min-width: 1024px) {
  .main-section .section-inner h1 {
    font-size: 80px;
    background-color: transparent;
    backdrop-filter: none;
    padding: 0;
    margin-bottom: 60px;
  }
}
@media (min-width: 1024px) {
  .main-section .section-inner h1 span {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    padding: 6px 16px;
    border-radius: 20px;
  }
}
@media (min-width: 1024px) {
  .main-section .section-inner h1 span:last-child {
    padding-top: 0;
    border-radius: 0 0 20px 20px;
  }
}
.main-section .section-inner .sub-p {
  display: inline-block;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 8px 12px;
  border-radius: 12px;
  backdrop-filter: blur(3px);
}
@media (min-width: 768px) {
  .main-section .section-inner .sub-p {
    font-size: 14px;
    letter-spacing: 2px;
  }
}
@media (min-width: 1024px) {
  .main-section .section-inner .sub-p {
    font-size: 20px;
  }
}
.main-section .section-inner.v2 {
  max-width: none;
}
@media (min-width: 1024px) {
  .main-section .section-inner.v2 {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .main-section .section-inner.v2 h1 {
    font-size: 64px;
  }
}
@media (min-width: 1024px) {
  .main-section .section-inner.v2 h1 {
    font-size: 72px;
  }
}
.main-section .section-inner.v2 h1 span:first-child {
  max-width: 360px;
  padding-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
@media (min-width: 1440px) {
  .main-section .section-inner.v2 h1 span:first-child {
    max-width: none;
    padding-bottom: 6px;
    border-bottom-right-radius: 20px;
  }
}
.main-section .section-inner.v2 h1 span:last-child {
  padding-top: 6px;
  border-top-right-radius: 20px;
}
@media (min-width: 1440px) {
  .main-section .section-inner.v2 h1 span:last-child {
    padding-top: 0;
    border-top-right-radius: 0;
  }
}
@media (min-width: 1024px) {
  .main-section .section-inner.v2 .sub-p {
    font-size: 16px;
  }
}
@media (min-width: 480px) {
  .main-section .main-menu {
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
  }
}
@media (min-width: 1024px) {
  .main-section .main-menu {
    top: 40px;
  }
}
.main-section .main-menu ul {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  text-transform: lowercase;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
}
@media (min-width: 768px) {
  .main-section .main-menu ul {
    font-size: 16px;
  }
}
.main-section .main-menu ul li {
  white-space: nowrap;
}
.main-section .main-menu ul li + li {
  margin-left: 20px;
}
@media (min-width: 480px) {
  .main-section .main-menu ul li + li {
    margin-left: 24px;
  }
}
.main-section .main-menu ul li a {
  color: #999999;
}
.main-section .main-menu ul li a:hover {
  color: #365edc;
}

.why-we-section .img-wrap {
  height: 240px;
  border-radius: 16px;
}
@media (min-width: 768px) {
  .why-we-section .img-wrap {
    height: 512px;
  }
}
@media (min-width: 1440px) {
  .why-we-section .img-wrap {
    height: 592px;
  }
}
.why-we-section .img-wrap-inner {
  position: absolute;
  right: 15px;
  bottom: 24px;
  left: 15px;
  z-index: 2;
  color: #fff;
}
@media (min-width: 768px) {
  .why-we-section .img-wrap-inner {
    right: 20px;
    bottom: 40px;
    left: 20px;
  }
}
.why-we-section .img-wrap-inner h2 {
  display: flex;
  align-items: center;
  font-size: 30px;
  line-height: 0.8;
  text-transform: uppercase;
  padding: 8px 0 8px 12px;
  border-left: 4px solid #365edc;
}
@media (min-width: 768px) {
  .why-we-section .img-wrap-inner h2 {
    font-size: 60px;
    padding: 14px 0 14px 24px;
  }
}
@media (min-width: 1440px) {
  .why-we-section .img-wrap-inner h2 {
    font-size: 82px;
    padding: 20px 0 20px 24px;
  }
}
.why-we-section .img-wrap-inner h2 span {
  display: block;
}
.why-we-section .img-wrap-inner h2 span:first-child {
  width: 132px;
}
@media (min-width: 768px) {
  .why-we-section .img-wrap-inner h2 span:first-child {
    width: 260px;
  }
}
@media (min-width: 1440px) {
  .why-we-section .img-wrap-inner h2 span:first-child {
    width: 350px;
  }
}
.why-we-section .img-wrap-inner h2 span:last-child {
  font-size: 64px;
}
@media (min-width: 768px) {
  .why-we-section .img-wrap-inner h2 span:last-child {
    font-size: 124px;
  }
}
@media (min-width: 1440px) {
  .why-we-section .img-wrap-inner h2 span:last-child {
    font-size: 174px;
  }
}
.why-we-section .descr-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.why-we-section .descr-block .descr-elem {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 16px;
  margin: 20px 0;
}
@media (min-width: 768px) {
  .why-we-section .descr-block .descr-elem {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .why-we-section .descr-block .descr-elem {
    margin: 5px 0;
  }
}
@media (min-width: 1440px) {
  .why-we-section .descr-block .descr-elem {
    padding: 40px;
  }
}
.why-we-section .descr-block .descr-elem:first-child {
  margin-top: 0;
}
.why-we-section .descr-block .descr-elem:last-child {
  margin-bottom: 0;
}
.why-we-section .descr-block .descr-elem dt {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .why-we-section .descr-block .descr-elem dt {
    font-size: 24px;
  }
}
@media (min-width: 1440px) {
  .why-we-section .descr-block .descr-elem dt {
    margin-bottom: 20px;
  }
}
.why-we-section .descr-block .descr-elem dd {
  color: #999999;
  font-size: 16px;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .why-we-section .descr-block .descr-elem dd {
    font-size: 20px;
  }
}

.work-steps {
  text-align: center;
}
.work-steps h2 {
  font-size: 28px;
}
.work-steps .steps-block {
  width: 330px;
  margin: 80px auto 0 auto;
}
@media (min-width: 1024px) {
  .work-steps .steps-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: auto;
  }
}
.work-steps .steps-block .steps-elem {
  position: relative;
  padding: 0 5px;
  margin-top: 40px;
}
@media (min-width: 1024px) {
  .work-steps .steps-block .steps-elem {
    width: 20%;
    margin-top: 0;
  }
}
.work-steps .steps-block .steps-elem dt {
  width: 76px;
  color: #365edc;
  font-size: 28px;
  font-weight: 700;
  background-color: #eef2ff;
  padding: 24px;
  border-radius: 24px;
  margin: 0 auto 24px auto;
}
@media (min-width: 1024px) {
  .work-steps .steps-block .steps-elem dt {
    margin: 0 auto 40px auto;
  }
}
.work-steps .steps-block .steps-elem dd {
  font-size: 20px;
  font-weight: 500;
}
.work-steps .steps-block .steps-elem .arr {
  position: absolute;
}
@media (min-width: 1024px) {
  .work-steps .steps-block .steps-elem .arr {
    display: none;
  }
}
.work-steps .steps-block .steps-elem.step1 dt, .work-steps .steps-block .steps-elem.step5 dt {
  color: #fff;
  background-color: #365edc;
  box-shadow: 0 4px 12px 0 rgba(54, 94, 220, 0.6);
}
.work-steps .steps-block .steps-elem.step2 .arr {
  right: 60px;
  bottom: 95px;
}
.work-steps .steps-block .steps-elem.step3 .arr {
  left: 30px;
  bottom: 95px;
}
.work-steps .steps-block .steps-elem.step4 .arr {
  right: 30px;
  bottom: 85px;
}
.work-steps .steps-block .steps-elem.step5 .arr {
  left: 30px;
  bottom: 100px;
}
.work-steps .steps-block .arr-wrap {
  display: none;
  width: 0;
  position: relative;
}
@media (min-width: 1024px) {
  .work-steps .steps-block .arr-wrap {
    display: block;
  }
}
.work-steps .steps-block .arr-wrap .svg-icon {
  position: absolute;
  top: 26px;
  left: 0;
  width: 92px;
  fill: #cccccc;
  transform: translateX(-50%);
}
@media (min-width: 1440px) {
  .work-steps .steps-block .arr-wrap .svg-icon {
    width: 102px;
  }
}
.work-steps .steps-block .arr-wrap .svg-icon.mirrored {
  transform: translateX(-50%) rotateX(180deg);
}

.loan {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 40px 15px;
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 480px) {
  .loan {
    padding: 40px 20px;
    border-radius: 16px;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .loan {
    height: 648px;
    padding: 40px;
  }
}
@media (min-width: 1440px) {
  .loan {
    height: 760px;
    padding: 100px;
  }
}
.loan .cover {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}
.loan .row {
  position: relative;
  z-index: 2;
}
@media (min-width: 1440px) {
  .loan .row {
    margin-right: -30px;
    margin-left: -30px;
  }
}
.loan .col {
  flex: 0 0 auto;
}
@media (min-width: 1440px) {
  .loan .col {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 768px) {
  .loan .col1 {
    width: 384px;
  }
}
@media (min-width: 1024px) {
  .loan .col1 {
    width: 484px;
  }
}
@media (min-width: 1440px) {
  .loan .col1 {
    width: 500px;
  }
}
@media (min-width: 768px) {
  .loan .col2 {
    width: 344px;
  }
}
@media (min-width: 1024px) {
  .loan .col2 {
    width: 420px;
  }
}
@media (min-width: 1440px) {
  .loan .col2 {
    width: 440px;
  }
}
.loan .res-block {
  font-size: 20px;
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 16px;
  margin-top: 24px;
}
@media (min-width: 768px) {
  .loan .res-block {
    font-size: 24px;
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .loan .res-block {
    padding: 40px;
  }
}
.loan .res-block h2 {
  color: #365edc;
  font-weight: 600;
}
.loan .res-block .res-sum {
  font-weight: 500;
  margin-top: 24px;
}
@media (min-width: 768px) {
  .loan .res-block .res-sum {
    margin-top: 40px;
  }
}

@media (min-width: 1440px) {
  .order-form-section {
    padding: 0 118px;
  }
}
.order-form-section .form-block h2 {
  color: #365edc;
  text-align: center;
}
@media (min-width: 1440px) {
  .order-form-section .row {
    margin-right: -30px;
    margin-left: -30px;
  }
}
.order-form-section .col {
  flex: 0 0 auto;
}
@media (min-width: 1440px) {
  .order-form-section .col {
    padding-right: 30px;
    padding-left: 30px;
  }
}
.order-form-section .col1 {
  width: 100%;
  order: 1;
}
@media (min-width: 768px) {
  .order-form-section .col1 {
    width: 50%;
    order: 0;
  }
}
@media (min-width: 1440px) {
  .order-form-section .col1 {
    width: 500px;
  }
}
.order-form-section .col2 {
  width: 100%;
}
@media (min-width: 768px) {
  .order-form-section .col2 {
    width: 50%;
  }
}
@media (min-width: 1440px) {
  .order-form-section .col2 {
    width: 604px;
  }
}
.order-form-section h1 {
  font-size: 34px;
  margin-bottom: 20px;
}
@media (min-width: 1440px) {
  .order-form-section h1 {
    font-size: 40px;
  }
}

.examples .nav {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .examples .nav {
    display: block;
    margin-bottom: 0;
  }
}
.examples .nav li {
  margin: 0 8px 0 0;
}
@media (min-width: 768px) {
  .examples .nav li {
    margin: 0 16px 0 0;
  }
}
@media (min-width: 1024px) {
  .examples .nav li {
    margin: 0 0 24px 0;
  }
}
.examples .nav .nav-btn {
  width: 100%;
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  background-color: #f2f2f2;
  padding: 12px 16px;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .examples .nav .nav-btn {
    font-size: 20px;
    padding: 20px 24px;
    border-radius: 12px;
  }
}
.examples .nav .nav-btn.active {
  color: #365edc;
  background-color: #eef2ff;
}
.examples .nav .nav-btn:hover {
  color: #365edc;
}
.examples .tab-content {
  position: relative;
  overflow: hidden;
}
.examples .tab-content > .tab-pane {
  display: block;
  position: absolute;
  bottom: 100%;
  width: 100%;
}
.examples .tab-content > .tab-pane.active {
  position: static;
}

.accordion-section .accordion-item {
  background-color: #fff;
  border: none;
  border-radius: 12px;
}
.accordion-section .accordion-item + .accordion-item {
  margin-top: 20px;
}
.accordion-section .accordion-button {
  justify-content: space-between;
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  padding: 16px 16px 12px 16px;
  border-radius: 12px;
  box-shadow: none;
}
@media (min-width: 768px) {
  .accordion-section .accordion-button {
    font-size: 20px;
    padding: 24px 24px 20px 24px;
  }
}
.accordion-section .accordion-button::after {
  width: 11px;
  height: 6px;
  background-image: url(../img/angle-down.png);
  background-size: contain;
  margin-left: 16px;
  transform: none;
}
@media (min-width: 768px) {
  .accordion-section .accordion-button::after {
    width: 20px;
    height: 11px;
  }
}
.accordion-section .accordion-button:not(.collapsed) {
  color: #365edc;
}
.accordion-section .accordion-button:not(.collapsed)::after {
  background-image: url(../img/angle-up.png);
}
.accordion-section .accordion-body {
  font-size: 12px;
  line-height: 1.4;
  padding: 12px 48px 16px 16px;
}
@media (min-width: 768px) {
  .accordion-section .accordion-body {
    font-size: 16px;
    padding: 20px 72px 24px 24px;
  }
}

.cases .prod-descr {
  height: auto;
}
@media (min-width: 1440px) {
  .cases .prod-descr {
    height: 648px;
  }
}
@media (min-width: 1440px) {
  .cases .prod-descr .descr-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.slogan-articles .row-c {
  position: relative;
  padding-left: 15px;
}
@media (min-width: 480px) {
  .slogan-articles .row-c {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .slogan-articles .row-c {
    padding-left: 0;
  }
}
.slogan-articles .row-c + .row-c {
  margin-top: 80px;
}
.slogan-articles .coll {
  padding-left: 18px;
  border-left: 2px solid #365edc;
}
@media (min-width: 768px) {
  .slogan-articles .coll {
    padding-left: 20px;
    border: none;
  }
}
@media (min-width: 1024px) {
  .slogan-articles .coll {
    padding-right: 40px;
  }
}
@media (min-width: 1440px) {
  .slogan-articles .coll {
    padding-right: 60px;
  }
}
.slogan-articles .colr {
  padding-left: 18px;
  border-left: 2px solid #365edc;
}
@media (min-width: 768px) {
  .slogan-articles .colr {
    position: relative;
    padding-left: 20px;
  }
}
@media (min-width: 1024px) {
  .slogan-articles .colr {
    padding-left: 40px;
  }
}
@media (min-width: 1440px) {
  .slogan-articles .colr {
    padding-left: 60px;
  }
}
.slogan-articles .circle {
  position: absolute;
  top: 0;
  left: 11px;
  width: 10px;
  height: 10px;
  background-color: #365edc;
  border-radius: 50%;
}
@media (min-width: 480px) {
  .slogan-articles .circle {
    left: 16px;
  }
}
@media (min-width: 768px) {
  .slogan-articles .circle {
    left: -6px;
  }
}
.slogan-articles .angle {
  position: absolute;
  bottom: -2px;
  left: 11px;
  width: 10px;
  height: 10px;
  border-right: 2px solid #365edc;
  border-bottom: 2px solid #365edc;
  transform: rotate(45deg);
}
@media (min-width: 480px) {
  .slogan-articles .angle {
    left: 16px;
  }
}
@media (min-width: 768px) {
  .slogan-articles .angle {
    left: -6px;
  }
}
.slogan-articles .img-wrap {
  height: 325px;
  overflow: hidden;
  border-radius: 12px;
}
@media (min-width: 1024px) {
  .slogan-articles .img-wrap {
    height: 432px;
  }
}
@media (min-width: 1440px) {
  .slogan-articles .img-wrap {
    height: 400px;
  }
}
.slogan-articles h2 {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.main-footer {
  color: #fff;
  background-color: #333333;
  padding: 20px 0;
  margin-top: 80px;
}
.main-footer .footer-inner {
  text-align: center;
}
@media (min-width: 768px) {
  .main-footer .footer-inner {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .main-footer .footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .main-footer .footer-elem {
    display: flex;
    align-items: center;
  }
}
.main-footer .logo-link {
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
}
@media (min-width: 1024px) {
  .main-footer .logo-link {
    margin-right: 40px;
  }
}
@media (min-width: 1440px) {
  .main-footer .logo-link {
    margin-right: 60px;
  }
}
.main-footer .links-group-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .main-footer .links-group-link {
    justify-content: flex-start;
  }
}
@media (min-width: 1024px) {
  .main-footer .links-group-link {
    margin-top: 0;
    margin-right: 40px;
  }
}
@media (min-width: 1440px) {
  .main-footer .links-group-link {
    margin-right: 60px;
  }
}
.main-footer .links-group-link .svg-icon {
  fill: #fff;
  margin-right: 12px;
}
.main-footer .soc-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .main-footer .soc-links {
    justify-content: flex-start;
  }
}
@media (min-width: 1024px) {
  .main-footer .soc-links {
    margin-top: 0;
  }
}
.main-footer .soc-links li + li {
  margin-left: 20px;
}
.main-footer .soc-links .svg-icon {
  fill: #fff;
}
.main-footer .signature {
  font-size: 12px;
  margin-top: 80px;
}
@media (min-width: 1024px) {
  .main-footer .signature {
    font-size: 16px;
    margin-top: 0;
  }
}
.main-footer .signature a {
  text-decoration: underline;
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
}

/* </main */
/* modals */
.modal {
  backdrop-filter: blur(3px);
}
.modal h2 {
  color: #365edc;
  text-align: center;
}
.modal .close-btn .svg-icon {
  fill: #cccccc;
  transition: fill 0.2s;
}
.modal .close-btn:hover .svg-icon {
  fill: #999999;
}

.modal-backdrop.show {
  opacity: 0.4;
}

@media (min-width: 480px) {
  .callback .modal-dialog {
    max-width: 440px;
  }
}
.callback .modal-content {
  border: none;
  border-radius: 20px;
}
.callback .modal-header {
  position: relative;
  padding: 20px 20px 0 20px;
  border: none;
}
@media (min-width: 480px) {
  .callback .modal-header {
    padding: 40px 40px 0 40px;
  }
}
.callback .modal-header .close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
}
.callback .modal-body {
  padding: 40px 20px;
}
@media (min-width: 480px) {
  .callback .modal-body {
    padding: 40px;
  }
}
.callback .modal-footer {
  padding: 0 20px 20px 20px;
  border: none;
}
@media (min-width: 480px) {
  .callback .modal-footer {
    padding: 0 40px 40px 40px;
  }
}
.callback .modal-footer > * {
  margin: 0;
}

.prod-card-modal .modal-dialog,
.gallery-modal .modal-dialog {
  max-width: none;
}
.prod-card-modal .modal-content,
.gallery-modal .modal-content {
  display: block;
  background-color: transparent;
  border: none;
  border-radius: 0;
}
.prod-card-modal .modal-inner,
.gallery-modal .modal-inner {
  position: relative;
}
.prod-card-modal .modal-inner .close-btn,
.gallery-modal .modal-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 0;
  z-index: 1;
}
@media (min-width: 480px) {
  .prod-card-modal .modal-inner .close-btn,
  .gallery-modal .modal-inner .close-btn {
    right: 16px;
  }
}

.gallery-modal .modal-dialog {
  margin-right: auto;
  margin-left: auto;
}
.gallery-modal .modal-inner .close-btn {
  right: 16px;
}

/* </modals */