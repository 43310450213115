$color-white: #fff;
$color-white_01: rgba(255, 255, 255, 0.1);
$color-light: #fafafa;
$color-light2: #f2f2f2;
$color-black: #333333;
$color-grey1: #999999;
$color-grey2: #cccccc;
$accent-color: #365edc;
$accent-color-hover: #2246bf;
$accent-color-hover2: #abbcfa;
$accent-color-back: #eef2ff;
$accent-color-back2: #e2e7f8;

$font1: 'Roboto', sans-serif;
$font-light: 'robotolight', sans-serif;
$font-regular: 'robotoregular', sans-serif;
$font-medium: 'robotomedium', sans-serif;
$font-bold: 'robotobold', sans-serif;

// Sass Breakpoint Mixin
@mixin breakpoint($point) {
	@if $point == psm {
		@media (min-width: 480px) { @content; }
	}
	@else if $point == pmd {
		@media (min-width: 768px) { @content; }
	}
	@else if $point == plg {
		@media (min-width: 1024px) { @content; }
	}
	@else if $point == pxl {
		@media (min-width: 1440px) { @content; }
	}
	@else if $point == pxxl {
		@media (min-width: 4000px) { @content; }
	}
	@else if $point { // allows custom breakpoints
		@media (min-width: $point) { @content; }
	}
}

html {
	font-size: 20px;
}
body {
	min-width: 360px;
	color: $color-black;
	font-size: 16px;
	font-family: $font1;
	font-weight: 400;
	line-height: 1;
	background-color: $color-light;
	@include breakpoint(pmd) {
		font-size: 18px;
	}
	@include breakpoint(plg) {
		font-size: 20px;
	}
}
.container {
	padding-right: 15px;
	padding-left: 15px;
	@include breakpoint(psm) {
		padding-right: calc(var(--bs-gutter-x)* 0.5);
		padding-left: calc(var(--bs-gutter-x)* 0.5);
	}
}
.row {
	margin-right: -15px;
	margin-left: -15px;
	@include breakpoint(psm) {
		margin-right: calc(-0.5* var(--bs-gutter-x));
		margin-left: calc(-0.5*var(--bs-gutter-x));
	}
	> * {
		padding-right: 15px;
		padding-left: 15px;
		@include breakpoint(psm) {
			padding-right: calc(var(--bs-gutter-x)* 0.5);
			padding-left: calc(var(--bs-gutter-x)* 0.5);
		}
	}
}
.row-custom {
	height: calc(100% + 2rem);
}
.fit-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.fit-contain {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.full-abs {
	position: absolute !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	line-height: 1;
}
h2 {
	font-size: 20px;
	@include breakpoint(psm) {
		font-size: 24px;
	}
}
h3 {
	font-size: 20px;
	@include breakpoint(pmd) {
		font-size: 24px;
	}
}
h4 {
	font-size: 20px;
}
.img-wrap {
	position: relative;
	overflow: hidden;
	border-radius: 8px;
	&.grad {
		.cover {
			z-index: 1;
			background-image: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 80%);
			@include breakpoint(pmd) {
				background-image: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 50%);
			}
		}
	}
}
.ordinary-text {
	font-size: 16px;
	font-weight: 300;
	line-height: 1.4;
	@include breakpoint(pxl) {
		font-size: 20px;
	}
	p + p {
		margin-top: 16px;
	}
	.sign {
		font-size: 12px;
		margin-top: 20px;
		@include breakpoint(plg) {
			font-size: 16px;
			margin-top: 40px;
		}
	}
	&.v2 {
		font-size: 16px;
		@include breakpoint(plg) {
			font-size: 20px;
		}
	}
	.icon-quot_left,
	.icon-quot_right {
		fill: $accent-color;
	}
}
* + .text-segm {
	margin-top: 60px;
}
.text-segm {
	h3 {
		margin-bottom: 40px;
	}
	h4 {
		margin-bottom: 24px;
	}
	&.right-img {
		@include breakpoint(plg) {
			display: flex;
		}
		.img-wrap {
			flex-shrink: 0;
			width: 100%;
			height: 300px;
			margin: 20px 0 0 0;
			@include breakpoint(pmd) {
				width: 440px;
			}
			@include breakpoint(plg) {
				margin: 0 0 0 20px;
			}
			@include breakpoint(pxl) {
				width: 520px;
				height: 360px;
			}
		}
	}
}
.ol-list {
	list-style: decimal;
	color: $accent-color;
	font-weight: 400;
	padding-left: 34px;
	li {
		+ li {
			margin-top: 24px;
		}
	}
	> li {
		padding-left: 6px;
	}
	ul {
		list-style-type: disc;
		list-style-position: outside;
		padding-left: 20px;
		margin-top: 24px;
	}
}
.ul-list {
	list-style-type: disc;
	list-style-position: outside;
	padding-left: 34px;
	> li {
		+ li {
			margin-top: 16px;
		}
		&::marker {
			color: $accent-color;
		}
	}
}
a.text-link {
	color: $accent-color;
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-color: transparent;
	text-underline-offset: 0.2em;
	text-decoration-thickness: 1px;
	transition: text-decoration-color 0.2s;
	&:hover {
		text-decoration-color: $accent-color;
	}
	&.v2 {
		text-decoration-style: dashed;
		text-decoration-color: $accent-color;
		&:hover {
			text-decoration-style: solid;
		}
	}
}
figure {
	.img-wrap {
		height: 280px;
		margin-bottom: 20px;
		@include breakpoint(pmd) {
			height: 420px;
		}
		@include breakpoint(plg) {
			height: 600px;
		}
		@include breakpoint(pxl) {
			height: 700px;
		}
	}
	figcaption {
		color: $color-grey1;
		font-weight: 400;
	}
}
.bg-image-cover {
	background-position: center;
	background-color: $color-grey2;
	background-size: cover;
	background-repeat: no-repeat;
}
.border-box {
	background-origin: border-box;
}
.accent-color {
	color: $accent-color !important;
}
.color-grey1 {
	color: $color-grey1 !important;
}
.fw400 {
	font-weight: 400;
}
.table-sepr-wrap {
	@include breakpoint(pmd) {
		margin-right: -20px;
		margin-left: -20px;
	}
	@include breakpoint(pxl) {
		margin-right: -40px;
		margin-left: -40px;
	}
}
.table-type1 {
	width: 100%;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	background-color: $color-white;
	border-radius: 12px;
	@include breakpoint(pmd) {
		font-size: 16px;
	}
	th {
		color: $color-white;
		font-size: 16px;
		font-weight: 700;
		background-color: $accent-color;
		padding: 14px 6px;
		@include breakpoint(pmd) {
			padding: 20px;
		}
		@include breakpoint(plg) {
			font-size: 20px;
		}
		&:first-child {
			padding-left: 15px;
			border-top-left-radius: 12px;
			@include breakpoint(pmd) {
				padding-left: 40px;
			}
		}
		&:last-child {
			padding-right: 15px;
			border-top-right-radius: 12px;
			@include breakpoint(pmd) {
				padding-right: 40px;
			}
		}
	}
	tr {
		td {
			position: relative;
			vertical-align: middle;
			padding: 20px 6px;
			@include breakpoint(pmd) {
				padding: 20px;
			}
			&::after {
				content: '';
				display: block;
				position: absolute;
				right: 6px;
				bottom: 0;
				left: 6px;
				border-bottom: 1px solid $color-light2;
				@include breakpoint(pmd) {
					right: 20px;
					left: 20px;
				}
			}
			&:first-child {
				text-align: left;
				padding-left: 15px;
				@include breakpoint(pmd) {
					text-align: center;
					padding-left: 40px;
				}
				&::after {
					left: 15px;
					@include breakpoint(pmd) {
						left: 40px;
					}
				}
			}
			&:last-child {
				padding-right: 15px;
				@include breakpoint(pmd) {
					padding-right: 40px;
				}
				&::after {
					right: 15px;
					@include breakpoint(pmd) {
						right: 40px;
					}
				}
			}
		}
		&:first-child {
			td {
				padding-top: 40px;
			}
		}
		&:last-child {
			td {
				padding-bottom: 40px;
				&::after {
					border: none;
				}
			}
		}
	}
	&.separate {
		background-color: transparent;
		border-collapse: separate;
		border-spacing: 0;
		@include breakpoint(pmd) {
			border-spacing: 20px 0;
		}
		@include breakpoint(pxl) {
			border-spacing: 40px 0;
		}
		tr {
			th {
				font-size: 12px;
				padding: 20px 6px;
				@include breakpoint(pmd) {
					font-size: 16px;
					padding: 20px;
					border-radius: 12px 12px 0 0;
				}
				@include breakpoint(plg) {
					font-size: 20px;
					padding: 20px 40px;
				}
			}
			td {
				background-color: $color-white;
				padding: 20px 10px;
				@include breakpoint(pmd) {
					padding: 20px;
				}
				@include breakpoint(plg) {
					padding: 20px 40px;
				}
				&:nth-child(1) {
					width: 65%;
					@include breakpoint(pmd) {
						width: 45%;
					}
					@include breakpoint(pxl) {
						width: 50%;
					}
				}
				&:nth-child(2),
				&:nth-child(3) {
					width: 17.5%;
					@include breakpoint(pmd) {
						width: 27.5%;
					}
					@include breakpoint(pxl) {
						width: 25%;
					}
				}
				&::after {
					right: 0;
					left: 0;
					@include breakpoint(pmd) {
						right: 20px;
						left: 20px;
					}
					@include breakpoint(plg) {
						right: 40px;
						left: 40px;
					}
				}
				&.rnd {
					@include breakpoint(pmd) {
						border-radius: 0 0 12px 12px;
					}
				}
				&.bg-transp {
					background-color: transparent;
				}
				.svg-icon {
					width: 16px;
					height: 16px;
					margin: 0 auto;
					@include breakpoint(pmd) {
						width: 20px;
						height: 20px;
					}
				}
			}
			&:first-child {
				td {
					padding-top: 20px;
					@include breakpoint(pmd) {
						padding-top: 40px;
					}
				}
			}
			&:last-child {
				td {
					padding-bottom: 20px;
					@include breakpoint(pmd) {
						padding-bottom: 40px;
					}
				}
			}
			&:last-child {
				td:first-child {
					border-bottom-left-radius: 12px;
				}
				td:last-child {
					border-bottom-right-radius: 12px;
					@include breakpoint(pmd) {
						border-bottom-right-radius: 0;
					}
				}
			}
		}
	}
	&.btm-table {
		tr {
			td {
				padding: 20px !important;
				p {
					color: $accent-color;
					font-size: 20px;
					font-weight: 500;
					margin-bottom: 36px;
					@include breakpoint(plg) {
						font-size: 24px;
					}
				}
			}
		}
	}
	&.color-var-col1 {
		tr {
			td {
				&:nth-child(1) {
					background-color: $accent-color-back;
					&::after {
						border-bottom-color: $accent-color-back2;
					}
				}
			}
		}
	}
	&.color-var-col2 {
		tr {
			td {
				&:nth-child(2) {
					background-color: $accent-color-back;
					&::after {
						border-bottom-color: $accent-color-back2;
					}
				}
			}
		}
	}
	&.color-var-col3 {
		tr {
			td {
				&:nth-child(3) {
					background-color: $accent-color-back;
					&::after {
						border-bottom-color: $accent-color-back2;
					}
				}
			}
		}
	}
}
.table-type2-wrap {
	background-color: $color-white;
	padding: 24px 20px 20px 20px;
	border-radius: 20px;
	@include breakpoint(plg) {
		padding: 40px 40px 20px 40px;
	}
	&.color-variant {
		background-color: $accent-color-back;
		.table-type2 tr td {
			border-bottom-color: $accent-color-back2;
		}
	}
}
.table-type2 {
	width: 100%;
	tr {
		td {
			color: $color-grey1;
			font-size: 12px;
			font-weight: 500;
			vertical-align: middle;
			padding: 20px 0;
			border-top: 1px solid $color-light2;
			border-bottom: 1px solid $color-light2;
			@include breakpoint(psm) {
				font-size: 16px;
			}
			&:last-child {
				text-align: right;
				color: $color-black;
				font-size: 16px;
				padding-left: 6px;
				@include breakpoint(psm) {
					font-size: 20px;
				}
			}
		}
		&:first-child {
			td {
				border-top: none;
			}
		}
		&:last-child {
			td {
				border-bottom: none;
			}
		}
	}
}

.bttn {
	color: $color-white;
	font-size: 16px;
	font-weight: 500;
	background-color: $accent-color;
	padding: 16px 20px;
	border-radius: 16px;
	&:hover {
		background-color: $accent-color-hover;
	}
}
.ellipsis-btn {
	width: 24px;
	height: 24px;
	color: $color-white;
	font-size: 16px;
	background-color: $accent-color;
	padding-top: 2px;
	border-radius: 8px;
}
.arr-btn {
	width: 72px;
	height: 40px;
	background-color: $color-light2;
	border-radius: 12px;
	&.grey {
		.svg-icon {
			fill: $color-grey2;
		}
	}
	&.accent {
		.svg-icon {
			fill: $color-white;
		}
	}
}
.close-btn {
	background-color: transparent;
	padding: 5px;
}

.custom-scroll {
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-track {
		background-color: $color-light2;
		border-radius: 2px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $color-grey2;
		border-radius: 2px;
	}
	&.v2 {
		&::-webkit-scrollbar-track {
			background-color: rgba(255, 255, 255, 0.3);
		}
	}
}
.hid-scroll {
	scrollbar-width: none;
	&::-webkit-scrollbar {
		width: 0;
	}
	&::-webkit-scrollbar-track {

	}
	&::-webkit-scrollbar-thumb {
		border-radius: 0;
	}
}
.hid-scroll-h {
	&::-webkit-scrollbar {
		width: auto;
		height: 0;
	}
}


/* input-placeholder */
input::-webkit-input-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
input::-moz-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
input:-ms-input-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
input:focus::-webkit-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
input:focus::-moz-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
input:focus:-ms-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
textarea::-webkit-input-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
textarea::-moz-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
textarea:-ms-input-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
textarea:focus::-webkit-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
textarea:focus::-moz-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
textarea:focus:-ms-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
input::-ms-clear {
	display: none;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
	display: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}


//fields
label {
	display: block;
}
.field-sect-gr {
	.field-sect:first-child {
		margin-top: 24px;
	}
}
.field-sect {
	position: relative;
	margin-top: 24px;
	&.w50 {
		width: calc(50% - 6px);
	}
	&:first-child {
		margin-top: 0;
	}
	&.after-symbol {
		&::after {
			content: '\20BD';
			position: absolute;
			right: 15px;
			bottom: 15px;
			color: $accent-color;
			font-size: 16px;
			font-weight: 400;
		}
		&.v2 {
			&::after {
				content: '%';
				right: auto;
				left: 52px;
				color: $color-grey1;
			}
		}
	}
	&.with-icon {
		.svg-icon {
			position: absolute;
			bottom: 16px;
			left: 16px;
			z-index: 1;
			fill: transparent;
			stroke: $accent-color;
		}
	}
}
.input-label {
	color: $accent-color;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 12px;
}
.field {
	width: 100%;
	height: 48px;
	color: $color-grey1;
	font-size: 16px;
	background-color: $color-light2;
	padding: 16px;
	border-radius: 8px;
	&.v2 {
		background-color: $color-white;
	}
	&.sm-field {
		width: 80px;
		&.pr30 {
			padding-right: 30px;
		}
	}
	&::placeholder {
		color: $color-grey1;
	}
	&.date {
		padding-left: 42px;
	}
}
.sm-rest-field {
	width: calc(100% - 80px - 12px);
}
.by + .by {
	margin-top: 16px;
}
.form-block {
	color: $color-grey1;
	font-size: 16px;
	background-color: $accent-color-back;
	padding: 20px;
	border-radius: 20px;
	&.v2 {
		background-color: $color-white;
		padding: 20px 15px;
		@include breakpoint(psm) {
			padding: 20px;
		}
		@include breakpoint(plg) {
			padding: 40px;
		}
	}
}
.checks-segm {
	background-color: $color-white;
	padding: 16px 20px;
	border-radius: 8px;
	input {
		display: none;
		&:checked {
			+ label {
				.svg-icon {
					fill: $accent-color;
				}
			}
		}
	}
	label {
		margin-right: 24px;
		cursor: pointer;
		&:last-child {
			margin-right: 0;
		}
	}
	.svg-icon {
		width: 16px;
		height: 16px;
		fill: $color-grey1;
		transition: fill 0.2s;
	}
}
.radio-gr {
	input {
		display: none;
		&:checked {
			+ label {
				color: $accent-color;
				background-color: $accent-color-back;
				border-color: $accent-color;
			}
		}
	}
	label {
		color: $color-grey1;
		background-color: $color-light2;
		padding: 15px 11px;
		border: 1px solid transparent;
		border-radius: 8px;
		cursor: pointer;
		transition: 0.2s;
		@include breakpoint(psm) {
			padding: 15px;
		}
		@include breakpoint(pmd) {
			padding: 15px 11px;
		}
		@include breakpoint(plg) {
			padding: 15px;
		}
	}
}
.jq-selectbox {
	width: 100%;
}
.jq-selectbox__select {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	color: $color-grey1;
	font-size: 16px;
	background-color: $color-light2;
	padding: 16px;
	border-radius: 8px;
	cursor: pointer;
}
.jq-selectbox__trigger {
	.svg-icon {
		position: relative;
		z-index: 10;
		fill: $accent-color;
		transform: rotate(180deg);
		&.mod {
			transform: none;
		}
	}
}
.jq-selectbox__dropdown {
	top: 0 !important;
	z-index: 9;
	width: 100%;
	background-color: $color-white;
	border-radius: 8px;
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
	ul {
		padding: 8px 0;
		li {
			padding: 8px 16px;
			cursor: pointer;
			&:hover {
				color: $accent-color;
			}
		}
	}
}
.flatpickr-calendar {
	font-size: 16px;
	border-radius: 8px;
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
	&::before,
	&::after {
		content: none;
	}
}
.numInputWrapper {
	display: none !important;
}
.flatpickr-weekdays {
	height: 36px;
	margin-top: 6px;
}
.flatpickr-current-month {
	.flatpickr-monthDropdown-months {
		color: $accent-color;
		font-size: 18px;
		font-weight: 400;
		&:hover {
			background-color: transparent;
		}
	}
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
	background-color: $color-light2;
	border-color: $color-light2;
}
.flatpickr-day {
	color: $color-grey1;
	border-radius: 8px;
	&.flatpickr-disabled,
	&.flatpickr-disabled:hover,
	&.prevMonthDay,
	&.nextMonthDay,
	&.notAllowed {
		color: #e5e5e5;
		&.prevMonthDay,
		&.nextMonthDay {
			color: #e5e5e5;
		}
	}
	&.selected,
	&.startRange,
	&.endRange {
		background-color: $accent-color;
		border-color: $accent-color;
		&.inRange,
		&.prevMonthDay,
		&.nextMonthDay,
		&:focus,
		&:hover {
			background-color: $accent-color;
			border-color: $accent-color;
		}
	}
	&.today {
		border-color: $color-grey2;
	}
}
.flatpickr-months {
	.flatpickr-prev-month,
	.flatpickr-next-month {
		top: 3px;
		svg {
			width: 30px;
			height: 14px;
			fill: $color-grey1;
		}
		&:hover {
			svg {
				fill: $accent-color;
			}
		}
		&.flatpickr-prev-month {
			left: 5px;
		}
		&.flatpickr-next-month {
			right: 5px;
		}
	}
}
// </fields

// icons
.svg-icon {
	display: block;
}
.icon-location {
	width: 13px;
	height: 16px;
}
.icon-phone,
.icon-cross {
	width: 16px;
	height: 16px;
}
.icon-arrow_long_left,
.icon-arrow_long_right {
	width: 34px;
	height: 16px;
}
.icon-telegram,
.icon-vk,
.icon-whatsapp,
.icon-youtube {
	width: 20px;
	height: 20px;
}
.icon-star {
	width: 22px;
	height: 22px;
}
.icon-dash,
.icon-check,
.icon-zoom {
	width: 24px;
	height: 24px;
}
.icon-triangle {
	width: 12px;
	height: 9px;
}
.icon-arrow_flex {
	width: 102px;
	height: 21px;
}
.icon-bracket_left,
.icon-bracket_right {
	width: 8px;
	height: 12px;
}
.icon-quot_left,
.icon-quot_right {
	width: 10px;
	height: 8px;
}
.icon-calendar {
	width: 16px;
	height: 16px;
	stroke-width: 1.59681;
	stroke-linecap: round;
	stroke-linejoin: round;
}
// </icons

// slick
.slick-arrow {
	position: absolute;
	z-index: 5;
	width: 72px;
	height: 48px;
	border-radius: 16px;
	&:hover {
		background-color: $accent-color !important;
		.svg-icon {
			fill: $color-white;
		}
	}
	.svg-icon {
		fill: $color-grey2;
	}
}
.slick-dots {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 5;
	padding: 12px;
	border-radius: 12px;
	li {
		font-size: 0;
		+ li {
			margin-left: 8px;
		}
		&.slick-active {
			button {
				background-color: $accent-color;
			}
		}
	}
	button {
		width: 12px;
		height: 12px;
		text-indent: -9999px;
		border-radius: 4px;
	}
}
// </slick

// tags
.tags {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: -12px;
	li {
		color: $accent-color;
		font-size: 12px;
		font-weight: 500;
		white-space: nowrap;
		background-color: $accent-color-back;
		padding: 12px 16px;
		border-radius: 12px;
		margin: 12px 12px 0 0;
	}
}
// </tags

// main
.main-header {
	padding: 20px 0;
	+ section {
		padding-top: 20px;
	}
	.header-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $color-white;
		padding: 12px;
		border-radius: 16px;
		@include breakpoint(pmd) {
			padding: 16px 16px 16px 24px;
			border-radius: 24px;
		}
	}
	.logo-link-group {
		.logo-link {
			font-size: 16px;
			@include breakpoint(pmd) {
				font-size: 40px;
			}
		}
		span {
			max-width: 160px;
			font-size: 16px;
		}
	}
	.links-group-elem {
		margin-right: 40px;
		@include breakpoint(pmd) {
			margin-right: 24px;
		}
		@include breakpoint(plg) {
			margin-right: 40px;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	.links-group-link {
		display: flex;
		align-items: center;
		font-size: 12px;
		@include breakpoint(pmd) {
			font-size: 16px;
		}
		+ .links-group-link {
			@include breakpoint(pmd) {
				margin-top: 16px;
			}
			@include breakpoint(pxl) {
				margin-top: 0;
				margin-left: 40px;
			}
		}
	}
	&.v2 {
		.header-inner {
			background-color: transparent;
			padding: 0;
		}
	}
}
.logo-link-group {
	color: $color-grey1;
	font-size: 16px;
	.logo-link {
		display: block;
		color: $color-black;
		font-family: $font1;
		font-size: 28px;
		font-weight: 500;
		margin-right: 16px;
	}
	span {
		display: block;
		max-width: 122px;
		font-size: 12px;
	}
}
.links-group-elem,
.sidebar-mov > ul > li {
	a > .svg-icon {
		margin-right: 12px;
		fill: $color-black;
	}
}
.sidebar-mov {
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: 50;
	top: 0;
	right: 100%;
	bottom: 0;
	width: 100%;
	background-color: $color-light;
	padding: 20px 15px 80px 15px;
	transition: right 0.3s;
	@include breakpoint(psm) {
		width: 360px;
	}
	&.mod {
		right: 0;
		@include breakpoint(psm) {
			right: calc(100% - 360px);
		}
	}
	ul {
		font-size: 16px;
		margin-top: 60px;
		li {
			+ li {
				margin-top: 40px;
			}
		}
	}
}
section {
	padding: 80px 0;
	.section-inner {
		> h2,
		> .h-wrap > h2 {
			font-size: 24px;
			border-left: 4px solid $accent-color;
			padding-left: 20px;
			margin-bottom: 40px;
			@include breakpoint(pmd) {
				font-size: 28px;
			}
		}
		> .h-wrap {
			margin-bottom: 40px;
			> h2 {
				margin-bottom: 0;
			}
		}
	}
}
.article-descr {
	max-height: 100%;
	overflow-y: auto;
	line-height: 1.4;
	h2 {
		margin-bottom: 20px;
	}
	header {
		margin-bottom: 20px;
		h2 {
			margin-bottom: 0;
		}
	}
}
.estimate-block {
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		display: block;
		font-size: 16px;
		font-weight: 500;
		line-height: 1;
		margin-left: 6px;
		@include breakpoint(pmd) {
			font-size: 24px;
		}
		@include breakpoint(plg) {
			margin-left: 12px;
		}
	}
}
.stars-block {
	display: flex;
	justify-content: center;
	align-items: center;
	.icon-star {
		width: 16px;
		height: 16px;
		fill: $color-grey2;
		margin-right: 4px;
		@include breakpoint(pmd) {
			padding-bottom: 0;
		}
		@include breakpoint(plg) {
			width: 22px;
			height: 22px;
		}
		&.active {
			fill: #ffab0a;
		}
	}
}
.slider1 {
	padding-bottom: 88px;
	margin-right: -15px;
	margin-left: -15px;
	@include breakpoint(psm) {
		margin-right: 0;
		margin-left: 0;
	}
	@include breakpoint(plg) {
		padding-bottom: 0;
	}
	.slick-list {
		@include breakpoint(psm) {
			border-radius: 16px;
		}
	}
	.img-wrap {
		height: 280px;
		border-radius: 0;
		@include breakpoint(psm) {
			height: 320px;
		}
		@include breakpoint(pmd) {
			height: 480px;
		}
		@include breakpoint(plg) {
			height: 720px;
		}
	}
	article {
		position: relative;
	}
	.article-descr {
		position: absolute;
		z-index: 2;
		right: 20px;
		bottom: 20px;
		left: 20px;
		max-height: 130px;
		color: $color-white;
		padding-right: 5px;
		margin-right: -5px;
		@include breakpoint(psm) {
			bottom: 30px;
			max-height: 155px;
		}
		@include breakpoint(pmd) {
			right: 40px;
			bottom: 40px;
			left: 40px;
			max-height: calc(100% - 80px);
		}
		@include breakpoint(plg) {
			bottom: 116px;
			max-height: 180px;
		}
		p {
			font-size: 16px;
			@include breakpoint(pmd) {
				font-size: 18px;
			}
			@include breakpoint(plg) {
				font-size: 20px;
			}
		}
	}
	.slick-arrow {
		bottom: 0;
		background-color: $color-light2;
		@include breakpoint(plg) {
			top: 50%;
			bottom: auto;
			background-color: $color-white_01;
			backdrop-filter: blur(3px);
			transform: translateY(-50%);
		}
		&:hover {
			background-color: $accent-color;
			.svg-icon {
				fill: $color-white;
			}
		}
		&.slick-prev {
			left: 15px;
			@include breakpoint(psm) {
				left: 0;
			}
			@include breakpoint(plg) {
				left: 40px;
			}
		}
		&.slick-next {
			right: 15px;
			@include breakpoint(psm) {
				right: 0;
			}
			@include breakpoint(plg) {
				right: 40px;
			}
		}
		.svg-icon {
			fill: $color-grey2;
			@include breakpoint(plg) {
				fill: $color-white;
			}
		}
	}
	.slick-dots {
		bottom: 6px;
		left: 50%;
		background-color: $color-light2;
		transform: translateX(-50%);
		@include breakpoint(plg) {
			bottom: 40px;
			background-color: $color-white_01;
			backdrop-filter: blur(3px);
		}
		button {
			background-color: $color-grey2;
			@include breakpoint(plg) {
				background-color: $color-white;
			}
		}
	}
}
.slider1a {
	.img-wrap {
		height: 280px;
		@include breakpoint(pmd) {
			height: 420px;
		}
		@include breakpoint(plg) {
			height: 600px;
		}
		@include breakpoint(pxl) {
			height: 720px;
		}
	}
	.slick-arrow {
		top: 50%;
		background-color: $color-light2;
		@include breakpoint(plg) {
			top: 50%;
			background-color: $color-white_01;
			backdrop-filter: blur(3px);
			transform: translateY(-50%);
		}
		&:hover {
			background-color: $accent-color;
			.svg-icon {
				fill: $color-white;
			}
		}
		&.slick-prev {
			left: 40px;
		}
		&.slick-next {
			right: 40px;
		}
		.svg-icon {
			fill: $color-grey2;
			@include breakpoint(plg) {
				fill: $color-white;
			}
		}
	}
	.slick-dots {
		left: 50%;
		bottom: 20px;
		background-color: $color-white_01;
		backdrop-filter: blur(3px);
		transform: translateX(-50%);
		@include breakpoint(pmd) {
			bottom: 40px;
		}
		button {
			background-color: $color-white;
		}
	}
}
.sliders_23 {
	overflow: hidden;
	background-color: $color-white;
	border-radius: 16px;
	@include breakpoint(pxl) {
		display: flex;
	}
}
.slider2 {
	@include breakpoint(pxl) {
		width: 620px;
	}
	.slide {
		height: 336px;
		border-radius: 0;
		@include breakpoint(pmd) {
			height: 480px;
		}
		@include breakpoint(plg) {
			height: 600px;
		}
		@include breakpoint(pxl) {
			height: 720px;
		}
	}
}
.slider3-wrap {
	position: relative;
	@include breakpoint(pxl) {
		width: 660px;
	}
	.pages {
		position: absolute;
		top: 56px;
		left: 50%;
		color: $color-grey2;
		font-weight: 700;
		transform: translateX(-50%);
		@include breakpoint(pmd) {
			top: 54px;
		}
		@include breakpoint(plg) {
			top: 52px;
		}
		@include breakpoint(pxl) {
			top: auto;
			bottom: 52px;
		}
	}
}
.slider3 {
	.slide {
		padding: 108px 15px 20px 15px;
		@include breakpoint(psm) {
			padding: 120px 20px 30px 20px;
		}
		@include breakpoint(pmd) {
			padding: 120px 40px 40px 40px;
		}
		@include breakpoint(pxl) {
			height: 720px;
			padding: 40px 40px 120px 40px;
		}
	}
	.article-descr {
		p {
			color: $color-grey1;
		}
	}
	.slick-arrow {
		top: 40px;
		background-color: $color-light2;
		@include breakpoint(pxl) {
			top: auto;
			bottom: 40px;
		}
		&.slick-prev {
			left: 20px;
			@include breakpoint(pmd) {
				left: 40px;
			}
		}
		&.slick-next {
			right: 20px;
			@include breakpoint(pmd) {
				right: 40px;
			}
		}
	}
}
.sliders_45 {
	@include breakpoint(plg) {
		display: flex;
	}
}
.slider4 {
	@include breakpoint(plg) {
		width: 420px;
	}
	@include breakpoint(pxl) {
		width: 620px;
	}
	.slide {
		position: relative;
		height: 480px;
		@include breakpoint(pxl) {
			height: 700px;
		}
		.img-wrap {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			transition: opacity 0.3s;
			&.on-top {
				z-index: 2;
			}
			&.hid {
				opacity: 0;
			}
		}
		.switch-btns {
			display: flex;
			position: absolute;
			bottom: 72px;
			left: 50%;
			z-index: 3;
			background-color: $color-white_01;
			backdrop-filter: blur(3px);
			padding: 4px;
			border: 1px solid $accent-color;
			border-radius: 16px;
			transform: translateX(-50%);
			@include breakpoint(pmd) {
				bottom: 40px;
			}
			button {
				width: 100px;
				border-radius: 12px;
				&.non-active {
					background-color: transparent;
				}
			}
		}
	}
	.slick-dots {
		display: flex !important;
		position: absolute;
		bottom: 20px;
		left: 50%;
		z-index: 5;
		background-color: $color-white_01;
		backdrop-filter: blur(3px);
		transform: translateX(-50%);
		@include breakpoint(pmd) {
			display: none !important;
		}
		button {
			background-color: $color-white;
		}
	}
}
.slider5 {
	@include breakpoint(plg) {
		width: 524px;
	}
	@include breakpoint(pxl) {
		width: 660px;
	}
	.slide {
		display: flex;
		align-items: center;
		padding-top: 40px;
		@include breakpoint(plg) {
			height: 480px;
			padding: 40px 0 40px 40px;
		}
		@include breakpoint(pxl) {
			height: 700px;
			padding: 40px 0 40px 60px;
		}
	}
	.article-descr {
		h2 {
			text-transform: uppercase;
		}
		footer {
			margin-top: 40px;
			button {
				line-height: 1;
				.svg-icon {
					fill: $color-white;
					margin-left: 20px;
				}
			}
		}
	}
}
.for-slider5-arrows {
	display: flex;
	.slick-arrow {
		position: static;
		background-color: $color-light2;
		margin-left: 16px;
	}
}
.slider6 {
	padding-bottom: 68px;
	margin-top: -20px;
	.slide {
		padding-top: 20px;
		padding-bottom: 20px;
		&:hover {
			.dig-segm {
				span {
					color: $color-white;
					background-color: $accent-color;
				}
			}
		}
		.slide-inner {
			@include breakpoint(pmd) {
				display: flex;
				align-items: center;
			}
		}
		.dig-segm {
			flex-shrink: 0;
			padding-bottom: 20px;
			@include breakpoint(pmd) {
				width: 100px;
				padding-bottom: 0;
			}
			@include breakpoint(pxl) {
				width: 160px;
			}
			span {
				width: 60px;
				height: 60px;
				color: $color-grey2;
				font-size: 24px;
				font-weight: 600;
				background-color: $color-light2;
				border-radius: 20px;
				margin: 0 auto;
				transition: background-color 0.3s;
				@include breakpoint(pmd) {
					margin: 0;
				}
			}
		}
		.article-descr {
			flex-grow: 1;
			align-items: center;
			background-color: $color-white;
			padding: 20px 15px;
			border-radius: 20px;
			@include breakpoint(psm) {
				padding: 20px;
			}
			@include breakpoint(pmd) {
				display: flex;
				padding: 40px;
			}
			.img-wrap {
				flex-shrink: 0;
				width: 100px;
				height: 100px;
				margin: 0 auto 20px auto;
				@include breakpoint(pmd) {
					width: 160px;
					height: 160px;
					margin: 0;
				}
			}
			.descr-wrap {
				flex-grow: 1;
				font-size: 16px;
				font-weight: 300;
				@include breakpoint(pmd) {
					padding-left: 40px;
				}
			}
			header {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				@include breakpoint(pmd) {
					justify-content: space-between;
					flex-wrap: nowrap;
				}
				h2 {
					font-size: 20px;
					@include breakpoint(pmd) {
						font-size: 24px;
					}
				}
				.estimate-block {
					width: 100%;
					@include breakpoint(pmd) {
						width: auto;
					}
				}
			}
		}
	}
	.slick-arrow {
		position: absolute;
		bottom: 0;
		z-index: 5;
		background-color: $color-light2;
	}
	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}
}
.slider7 {
	@include breakpoint(pmd) {
		margin-right: -20px;
		margin-left: -20px;
	}
	.slide {
		@include breakpoint(pmd) {
			padding-right: 20px;
			padding-left: 20px;
		}
	}
	.img-wrap {
		height: 480px;
		.cover {
			z-index: 1;
			background-image: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 50%);
		}
		.article-cont {
			display: flex;
			align-items: flex-end;
			z-index: 2;
			color: $color-white;
			font-size: 16px;
			padding: 40px 15px;
			transition: background-color 0.3s, backdrop-filter 0.3s;
			@include breakpoint(psm) {
				padding: 40px;
			}
			.descr-block {
				visibility: hidden;
				opacity: 0;
				transition: opacity 0.3s;
			}
			.descr-block-elem {
				margin-bottom: 40px;
			}
			.sub-h {
				color: $color-grey2;
				margin-bottom: 12px;
			}
			.like-h {
				font-size: 24px;
			}
		}
		&:hover {
			.article-cont {
				background-color: rgba(0, 0, 0, 0.4);
				backdrop-filter: blur(3px);
				.descr-block {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
}
.fz0 {
	.slick-slide {
		> div {
			font-size: 0;
		}
	}
}
.slider6,
.for-slider-arrows {
	.slick-dots {
		position: absolute;
		bottom: 6px;
		left: 50%;
		z-index: 5;
		background-color: $color-light2;
		transform: translateX(-50%);
		button {
			background-color: $color-grey2;
		}
	}
}
.for-slider-arrows {
	display: flex;
	justify-content: space-between;
	position: relative;
	.slick-arrow {
		position: static;
		background-color: $color-light2;
		@include breakpoint(pmd) {
			margin-left: 16px;
		}
		&:first-child {
			margin-left: 0;
		}
	}
	.slick-dots {
		display: flex !important;
		@include breakpoint(pmd) {
			display: none !important;
		}
	}
}
.for-slider-modal-arrows,
.for-slider12-arrows,
.for-slider14-arrows,
.for-slider17-arrows,
.for-slider18-arrows {
	.slick-dots {
		display: flex !important;
	}
}
.slider8,
.slider9 {
	margin: 0 -15px;
	@include breakpoint(psm) {
		margin: -20px;
	}
	.slick-slide {
		> div {
			display: flex !important;
		}
	}
	.slide {
		padding: 0 15px;
		@include breakpoint(psm) {
			padding: 20px;
		}
		.slide-link,
		.slide-segm {
			display: block;
			cursor: pointer;
			&:hover {
				.slide-title {
					text-decoration-color: $color-black;
				}
			}
			> span {
				display: block;
			}
		}
		.img-wrap {
			height: 260px;
			overflow: hidden;
			background-color: $color-white;
			border-radius: 4px;
		}
		.slide-title {
			font-size: 20px;
			font-weight: 700;
			text-align: center;
			text-decoration-line: underline;
			text-decoration-style: solid;
			text-decoration-color: transparent;
			text-underline-offset: 0.15em;
			text-decoration-thickness: 2px;
			margin-top: 20px;
			transition: text-decoration-color 0.2s;
			button {
				font-weight: 700;
			}
		}
		.price {
			display: inline-block !important;
			color: $color-white;
			font-size: 16px;
			font-weight: 700;
			background-color: $accent-color;
			padding: 8px;
			border-radius: 8px;
			margin-top: 20px;
		}
	}
	.modal-cont {
		position: fixed;
		z-index: 1000;
		width: 100vw;
		height: 100vw;
		top: -10000px;
		left: -10000px;
		visibility: hidden;
		opacity: 0;
	}
}
.slider9 {
	margin: 0 -15px;
	@include breakpoint(psm) {
		margin: -20px;
	}
	@include breakpoint(plg) {
		margin: -30px -40px;
	}
	.slide {
		padding: 0 15px;
		@include breakpoint(psm) {
			padding: 20px;
		}
		@include breakpoint(plg) {
			padding: 30px 40px;
		}
	}
}
.slider10-thumbs {
	margin: 20px -6px 0 -6px;
	@include breakpoint(pmd) {
		margin: 20px -8px 0 -8px;
	}
	@include breakpoint(pxl) {
		margin: -10px 0;
	}
	.slick-current {
		.thumb {
			border-color: $accent-color;
		}
	}
	.slide {
		padding: 0 8px;
		@include breakpoint(pxl) {
			padding: 10px 0;
		}
	}
	.thumb {
		width: 100%;
		height: 100px;
		overflow: hidden;
		background-color: $color-white;
		border: 1px solid transparent;
		border-radius: 12px;
		cursor: pointer;
		transition: border-color 0.2s;
		@include breakpoint(pxl) {
			width: 100%;
			height: 120px;
		}
		.img-wrap {
			height: 100%;
		}
	}
}
.slider11,
.slider16 {
	margin: 0 -15px;
	@include breakpoint(pmd) {
		margin: -20px;
	}
	.slide {
		padding: 0 15px;
		@include breakpoint(pmd) {
			padding: 20px;
		}
		.img-wrap {
			display: block;
			overflow: hidden;
			height: 345px;
			border-radius: 8px;
			@include breakpoint(pmd) {
				width: 100%;
				height: 290px;
			}
		}
	}
}
.slider12,
.slider17 {
	.slide {
		padding: 0 15px;
		@include breakpoint(pmd) {
			padding: 0 20px;
		}
		.img-wrap {
			display: block;
			overflow: hidden;
			height: 345px;
			border-radius: 8px;
			@include breakpoint(pmd) {
				height: 512px;
			}
		}
	}
}
.slider13 {
	margin-right: -15px;
	margin-left: -15px;
	@include breakpoint(psm) {
		margin-right: 0;
		margin-left: 0;
	}
	.slide {
		position: relative;
		height: 212px;
		overflow: hidden;
		@include breakpoint(psm) {
			border-radius: 12px;
		}
		@include breakpoint(pmd) {
			height: 400px;
		}
		@include breakpoint(plg) {
			height: 600px;
		}
		@include breakpoint(pxl) {
			height: 800px;
		}
		.title {
			position: absolute;
			top: 12px;
			z-index: 5;
			color: $accent-color;
			font-size: 12px;
			font-weight: 700;
			background-color: $color-white;
			padding: 8px 12px;
			border-radius: 8px;
			@include breakpoint(pmd) {
				top: 24px;
				font-size: 16px;
				padding: 16px 20px;
				border-radius: 16px;
			}
			@include breakpoint(plg) {
				top: 40px;
				font-size: 20px;
			}
			&.left {
				left: 25%;
				transform: translateX(-50%);
			}
			&.right {
				right: 25%;
				transform: translateX(50%);
			}
		}
		.over-wrap {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			padding-left: 18px;
			width: calc(50% + 18px);
			.over-wrap-inner {
				position: relative;
				overflow: hidden;
				width: 100%;
				height: 100%;
			}
			.img-wrap-esp {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
			}
			.over-wrap-line {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				z-index: 2;
				width: 2px;
				background-color: $accent-color;
				cursor: e-resize;
			}
			.arrows-gr {
				position: absolute;
				top: 50%;
				left: 1px;
				z-index: 1;
				display: flex;
				transform: translateY(-50%);
				.wwu-left-btn,
				.wwu-right-btn {
					width: 18px;
					height: 52px;
					background-color: $accent-color;
					.svg-icon {
						fill: $color-white;
					}
				}
				.wwu-left-btn {
					border-radius: 12px 0 0 12px;
				}
				.wwu-right-btn {
					border-radius: 0 12px 12px 0;
				}
			}
		}
	}
}
.slider14 {
	.video-wrap {
		height: 196px;
		overflow: hidden;
		background-color: $color-light2;
		border-radius: 8px;
		@include breakpoint(400px) {
			height: 250px;
		}
		@include breakpoint(pmd) {
			height: 416px;
		}
		@include breakpoint(plg) {
			height: 540px;
		}
		@include breakpoint(pxl) {
			height: 720px;
		}
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.slider15 {
	display: none;
	margin-right: -10px;
	margin-left: -10px;
	@include breakpoint(pmd) {
		display: block;
	}
	@include breakpoint(pxl) {
		margin-right: -20px;
		margin-left: -20px;
	}
	.slide {
		padding: 40px 10px;
		@include breakpoint(pxl) {
			padding: 40px 20px;
		}
		.img-wrap {
			height: 120px;
			overflow: hidden;
			border: 2px solid transparent;
			border-radius: 8px;
			cursor: pointer;
			transition: border-color 0.2s;
			@include breakpoint(plg) {
				height: 160px;
			}
			@include breakpoint(pxl) {
				height: 225px;
			}
		}
	}
	.slick-current {
		.slide {
			.img-wrap {
				border-color: $accent-color;
				box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
				@include breakpoint(pxl) {
					box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.4);
				}
			}
		}
	}
}
.slider17 {
	width: 280px;
	margin-right: auto;
	margin-left: auto;
	@include breakpoint(psm) {
		width: auto;
		margin-right: -20px;
		margin-left: -20px;
	}
	.slide {
		.img-wrap {
			height: 360px;
			@include breakpoint(psm) {
				height: 320px;
			}
			@include breakpoint(pmd) {
				height: 480px;
			}
			@include breakpoint(plg) {
				height: 620px;
			}
			@include breakpoint(pxl) {
				height: 560px;
			}
		}
	}
}
.slider18 {
	overflow: hidden;
	border-radius: 12px;
	.img-wrap {
		height: 345px;
		overflow: hidden;
		@include breakpoint(pmd) {
			height: 364px;
		}
		@include breakpoint(plg) {
			height: 532px;
		}
		@include breakpoint(pxl) {
			height: 400px;
		}
	}
}
.slider19 {
	display: none;
	margin: 40px -10px 0 -10px;
	@include breakpoint(pmd) {
		display: block;
	}
	.slide {
		padding: 0 10px;
	}
	.img-wrap {
		height: 120px;
		overflow: hidden;
		border: 1px solid transparent;
		border-radius: 12px;
		cursor: pointer;
		transition: border-color 0.2s;
	}
	.slick-current {
		.img-wrap {
			border-color: $accent-color;
		}
	}
}
.slider20 {
	.slick-list,
	.slick-track,
	.slick-slide > div,
	.slide {
		@include breakpoint(pxl) {
			height: 100%;
		}
	}
	.slide {
		@include breakpoint(pxl) {
			display: flex !important;
			flex-direction: column;
		}
	}
}
.for-slider21-arrows {
	.slick-arrow {
		margin-left: 16px;
	}
}
.slider-modal-nest {
	width: 100vw;
	height: 0;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
}
.slider-modal,
.no-slider {
	.slick-list {
		border-radius: 16px;
	}
	.slide {
		overflow: hidden;
		background-color: $color-white;
		.img-wrap {
			height: 600px;
			border-radius: 0;
		}
	}
}
.no-slider {
	.slide {
		overflow: hidden;
		border-radius: 12px;
	}
}
.inimg-slider {
	.slick-list {
		border-radius: 8px;
	}
	.slide {
		padding: 0;
		.img-wrap {
			border-radius: 0;
		}
	}
	.slick-dots {
		position: absolute;
		bottom: 20px;
		left: 50%;
		z-index: 1;
		padding: 0;
		transform: translateX(-50%);
		button {
			width: 8px;
			height: 8px;
			background-color: $color-grey2;
		}
	}
}
.for-zoom {
	position: relative;
	.button-zoom {
		position: absolute;
		top: 34px;
		right: 34px;
		z-index: 10;
		padding: 6px;
	}
	.icon-zoom {
		fill: $color-white;
	}
}
.cards {
	.col.hdn {
		display: none;
	}
	article {
		position: relative;
		overflow: hidden;
		height: 480px;
		color: $color-white;
		border-radius: 8px;
		transition: height 0.2s;
		.img-wrap {
			position: absolute;
			top: -3px;
			right: -3px;
			bottom: -3px;
			left: -3px;
			z-index: 1;
		}
		.article-descr {
			position: relative;
			z-index: 2;
			overflow: hidden;
			height: 100%;
			padding: 20px;
			transition: background-color 0.3s, backdrop-filter 0.3s;
			@include breakpoint(psm) {
				padding: 40px;
			}
			header {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				overflow: hidden;
				height: 40px;
				@include breakpoint(psm) {
					height: 48px;
				}
				a {
					display: block;
					padding: 0 0 3px 6px;
					&:hover {
						.svg-icon {
							fill: $accent-color-hover2;
						}
					}
				}
			}
			.svg-icon {
				fill: $color-white;
				transition: fill 0.2s;
			}
			p {
				color: $color-grey2;
				font-size: 16px;
				vertical-align: middle;
				a {
					color: $color-white;
					.svg-icon {
						display: inline-block;
						fill: $color-white;
						margin: 0 0 2px 6px;
					}
					&:hover {
						color: $accent-color-hover2;
						.svg-icon {
							fill: $accent-color-hover2;
						}
					}
				}
			}
		}
		.article-descr-inner {
			position: relative;
			height: 100%;
			overflow: hidden;
		}
		.move-block {
			position: absolute;
			top: calc(100% - 40px);
			right: 0;
			left: 0;
			height: 100%;
			transition: top 0.5s;
			@include breakpoint(psm) {
				top: calc(100% - 48px);
			}
		}
		&:hover {
			.article-descr {
				background-color: rgba(0, 0, 0, 0.4);
				backdrop-filter: blur(3px);
			}
			.move-block {
				top: 0;
			}
		}
	}
}
.map {
	position: relative;
	overflow: hidden;
	height: 760px;
	background-color: #f2f2f2;
	border-radius: 16px;
	.inner-map {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
	}
	.contacts-block {
		position: absolute;
		top: 70px;
		right: 20px;
		left: 20px;
		z-index: 10;
		text-align: center;
		background-color: $color-white;
		padding: 60px 40px;
		border-radius: 20px;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.3s;
		@include breakpoint(psm) {
			right: 40px;
			left: 40px;
		}
		@include breakpoint(pmd) {
			right: auto;
			width: 336px;
			padding: 60px;
			visibility: visible;
			opacity: 1;
		}
		&.mod {
			visibility: visible;
			opacity: 1;
		}
		header {
			margin-bottom: 40px;
			h2 {
				color: $accent-color;
				font-size: 28px;
			}
		}
		.contacts {
			font-size: 20px;
			font-weight: 500;
			> li {
				+ li {
					margin-top: 32px;
				}
			}
			.label {
				display: block;
				color: $color-grey2;
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 8px;
			}
		}
		.soc-links {
			margin-top: 16px;
			li {
				+ li {
					margin-left: 32px;
				}
			}
			.svg-icon {
				fill: $color-black;
			}
		}
		.close-btn {
			position: absolute;
			top: 24px;
			right: 24px;
			z-index: 5;
			.svg-icon {
				fill: $color-grey2;
			}
		}
	}
	.picts-wrap {
		position: absolute;
		top: 580px;
		left: 0;
		z-index: 10;
		width: 100%;
		overflow-x: auto;
		padding: 20px;
		@include breakpoint(psm) {
			padding-left: 40px;
		}
		@include breakpoint(pmd) {
			width: auto;
			padding-right: 0;
		}
		.picts {
			display: flex;
			li {
				flex-shrink: 0;
				width: 160px;
				+ li {
					margin-left: 16px;
				}
			}
			.img-wrap {
				display: block;
				width: 100%;
				height: 90px;
				position: relative;
				border-radius: 16px;
				box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
				transition: box-shadow 0.2s;
				&:hover {
					box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.4);
				}
				.img-count-in-modal {
					position: absolute;
					top: 8px;
					right: 8px;
					z-index: 1;
					width: 26px;
					height: 24px;
					color: $color-white;
					font-size: 12px;
					text-align: center;
					background-color: $accent-color;
					border-radius: 8px;
				}
			}
			&::after {
				content: '';
				display: inline-block;
				width: 20px;
				flex-shrink: 0;
				@include breakpoint(psm) {
					width: 40px;
				}
				@include breakpoint(pmd) {
					width: 0;
				}
			}
		}
	}
	.btn-wrap {
		position: absolute;
		top: 532px;
		right: 0;
		left: 0;
		z-index: 5;
		text-align: center;
	}
}
.with-left-image {
	@include breakpoint(pmd) {
		display: flex;
		align-items: flex-start;
	}
	@include breakpoint(plg) {
		align-items: center;
	}
	h2 {
		font-size: 24px;
		margin-bottom: 20px;
		@include breakpoint(plg) {
			font-size: 28px;
			margin-bottom: 40px;
		}
	}
	.img-wrap {
		height: 480px;
		margin-bottom: 60px;
		@include breakpoint(psm) {
			height: 600px;
		}
		@include breakpoint(pmd) {
			width: 300px;
			height: 420px;
			margin-bottom: 0;
		}
		@include breakpoint(plg) {
			width: 420px;
			height: 600px;
		}
	}
	.section-descr {
		@include breakpoint(pmd) {
			width: calc(100% - 300px);
			padding-left: 40px;
		}
		@include breakpoint(plg) {
			width: calc(100% - 420px);
		}
		@include breakpoint(pxl) {
			padding-left: 60px;
		}
	}
}
.tariff-choose {
	.icon-check {
		fill: $accent-color;
	}
	.icon-dash {
		fill: $color-grey2;
	}
	.under-table-block {
		.utb-elem {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 16px;
			font-weight: 500;
			background-color: $color-white;
			padding: 15px;
			border-radius: 20px;
			margin-top: 20px;
			.heading-p {
				color: $accent-color;
				font-size: 20px;
				margin-bottom: 8px;
			}
			button {
				min-width: 140px;
			}
		}
	}
}
.price-list {
	.table-type2-wrap {
		h2 {
			color: $accent-color;
			font-size: 24px;
			text-align: center;
			margin-bottom: 20px;
			@include breakpoint(plg) {
				font-size: 28px;
			}
		}
	}
}
.catalog {
	.cat-menu {
		color: $accent-color;
		font-size: 20px;
		li + li {
			margin-top: 32px;
		}
		> li {
			transition: margin-top 0.5s;
			> span {
				display: flex;
				align-items: center;
				button {
					visibility: hidden;
					margin-right: 12px;
					.svg-icon {
						fill: $accent-color;
						transform: rotate(180deg);
					}
				}
			}
			> .ul-wrap {
				max-height: 0;
				overflow-y: hidden;
				transition: max-height 0.5s;
				> ul {
					padding-left: 40px;
				}
				&.ofya {
					overflow-y: auto;
				}
			}
			&:has(ul) {
				+ li {
					margin-top: 0;
				}
				> span {
					padding-bottom: 32px;
					button {
						visibility: visible;
					}
				}
			}
			&.mod {
				> span {
					button {
						.svg-icon {
							transform: none;
						}
					}
				}
				> .ul-wrap {
					max-height: 390px;
					padding-top: 3px;
					padding-bottom: 3px;
					margin-top: -3px;
					margin-bottom: -3px;
				}
			}
			&.mod2 {
				margin-top: 32px;
			}
		}
	}
}
.prod-card {
	background-color: $color-white;
	padding: 40px 15px;
	margin: 0 -15px;
	@include breakpoint(psm) {
		padding: 40px 20px;
		border-radius: 12px;
		margin: 0;
	}
	@include breakpoint(pmd) {
		padding: 40px;
	}
	@include breakpoint(plg) {
		background-color: transparent;
		padding: 0;
	}
	.row-c {
		margin: 0 -10px;
		> .col {
			flex: 0 0 auto;
			padding: 0 10px;
		}
	}
	.thumbs-wrap {
		order: 1;
		@include breakpoint(pmd) {
			width: 410px;
		}
		@include breakpoint(plg) {
			width: 100%;
		}
		@include breakpoint(pxl) {
			width: 140px;
			order: 0;
		}
	}
	.slider10 {
		order: 0;
		@include breakpoint(pmd) {
			width: 410px;
		}
		@include breakpoint(plg) {
			width: 100%;
		}
		@include breakpoint(plg) {
			width: 100%;
		}
		@include breakpoint(pxl) {
			width: 500px;
			order: 1;
		}
	}
	.slider10 .img-wrap,
	.prod-descr {
		overflow: hidden;
		border-radius: 12px;
	}
	.slider10 .img-wrap {
		height: 456px;
		@include breakpoint(pxl) {
			height: 640px;
		}
	}
	header {
		margin-bottom: 32px;
		@include breakpoint(pxl) {
			margin-bottom: 40px;
		}
		h2 {
			color: $color-black;
			font-size: 28px;
			margin-bottom: 16px;
		}
		.price-block {
			display: flex;
			align-items: center;
			.price {
				color: $color-white;
				font-size: 24px;
				font-weight: 600;
				background-color: $accent-color;
				padding: 12px;
				border-radius: 12px;
				margin-right: 16px;
			}
			.old-price {
				color: $color-grey2;
				font-size: 20px;
				font-weight: 600;
				text-decoration: line-through;
			}
		}
		&.v2 {
			h2 {
				font-size: 24px;
				margin-bottom: 0;
			}
		}
	}
	.prod-descr {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: $color-white;
		@include breakpoint(plg) {
			height: 576px;
			padding: 40px;
		}
		@include breakpoint(pxl) {
			height: 640px;
		}
		header,
		footer {
			flex-shrink: 0;
		}
		footer {
			margin-top: 32px;
			@include breakpoint(pxl) {
				margin-top: 40px;
			}
			button {
				min-width: 150px;
			}
		}
		.descr-cont {
			flex-grow: 1;
			font-size: 16px;
			line-height: 1.4;
			@include breakpoint(plg) {
				overflow-y: auto;
				padding-right: 6px;
				margin-right: -6px;
			}
			* + p {
				margin-top: 24px;
			}
			ul {
				font-weight: 400;
				line-height: 1;
				li {
					display: flex;
					+ li {
						margin-top: 20px;
					}
					span {
						flex-shrink: 0;
						&:first-child {
							order: 0;
							color: $color-grey2;
						}
						&:last-child {
							order: 2;
							font-weight: 500;
						}
					}
					&::after {
						content: '';
						order: 1;
						display: block;
						flex-grow: 1;
						height: 16px;
						border-bottom: 1px dashed $color-grey2;
						margin: 0 6px;
					}
				}
				&.v2 {
					background-color: $accent-color-back;
					padding: 20px;
					border-radius: 8px;
					li {
						span {
							&:first-child {
								color: $color-black;
							}
							&:last-child {
								color: $accent-color;
							}
						}
						&::after {
							border-bottom-color: $color-black;
						}
					}
				}
			}
		}
	}
}
.main-section {
	position: relative;
	height: 280px;
	padding: 20px 15px;
	margin: 0 -15px;
	@include breakpoint(psm) {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 480px;
		padding: 20px;
		border-radius: 16px;
		margin: 0;
	}
	@include breakpoint(pmd) {
		height: 600px;
	}
	@include breakpoint(plg) {
		height: 800px;
	}
	@include breakpoint(pxl) {
		height: 720px;
		padding: 40px;
	}
	.section-inner {
		max-width: 840px;
		color: $color-white;
		text-align: center;
		margin-top: 40px;
		h1 {
			font-size: 32px;
			text-transform: uppercase;
			background-color: rgba(0, 0, 0, 0.3);
			backdrop-filter: blur(3px);
			padding: 6px 16px;
			border-radius: 20px;
			margin-bottom: 40px;
			@include breakpoint(pmd) {
				font-size: 64px;
			}
			@include breakpoint(plg) {
				font-size: 80px;
				background-color: transparent;
				backdrop-filter: none;
				padding: 0;
				margin-bottom: 60px;
			}
			span {
				@include breakpoint(plg) {
					display: inline-block;
					background-color: rgba(0, 0, 0, 0.3);
					backdrop-filter: blur(3px);
					padding: 6px 16px;
					border-radius: 20px;
				}
				&:last-child {
					@include breakpoint(plg) {
						padding-top: 0;
						border-radius: 0 0 20px 20px;
					}
				}
			}
		}
		.sub-p {
			display: inline-block;
			font-size: 12px;
			line-height: 1.4;
			letter-spacing: 1px;
			background-color: rgba(0, 0, 0, 0.3);
			padding: 8px 12px;
			border-radius: 12px;
			backdrop-filter: blur(3px);
			@include breakpoint(pmd) {
				font-size: 14px;
				letter-spacing: 2px;
			}
			@include breakpoint(plg) {
				font-size: 20px;
			}
		}
		&.v2 {
			max-width: none;
			@include breakpoint(plg) {
				text-align: left;
			}
			h1 {
				@include breakpoint(pmd) {
					font-size: 64px;
				}
				@include breakpoint(plg) {
					font-size: 72px;
				}
				span {
					&:first-child {
						max-width: 360px;
						padding-bottom: 0;
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 0;
						@include breakpoint(pxl) {
							max-width: none;
							padding-bottom: 6px;
							border-bottom-right-radius: 20px;
						}
					}
					&:last-child {
						padding-top: 6px;
						border-top-right-radius: 20px;
						@include breakpoint(pxl) {
							padding-top: 0;
							border-top-right-radius: 0;
						}
					}
				}
			}
			.sub-p {
				@include breakpoint(plg) {
					font-size: 16px;
				}
			}
		}
	}
	.main-menu {
		@include breakpoint(psm) {
			position: absolute;
			top: 20px;
			left: 50%;
			z-index: 1;
			transform: translateX(-50%);
		}
		@include breakpoint(plg) {
			top: 40px;
		}
		ul {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			font-weight: 500;
			text-transform: lowercase;
			background-color: $color-white;
			padding: 16px;
			border-radius: 16px;
			@include breakpoint(pmd) {
				font-size: 16px;
			}
			li {
				white-space: nowrap;
				+ li {
					margin-left: 20px;
					@include breakpoint(psm) {
						margin-left: 24px;
					}
				}
				a {
					color: $color-grey1;
					&:hover {
						color: $accent-color;
					}
				}
			}
		}
	}
}
.why-we-section {
	.img-wrap {
		height: 240px;
		border-radius: 16px;
		@include breakpoint(pmd) {
			height: 512px;
		}
		@include breakpoint(pxl) {
			height: 592px;
		}
	}
	.img-wrap-inner {
		position: absolute;
		right: 15px;
		bottom: 24px;
		left: 15px;
		z-index: 2;
		color: $color-white;
		@include breakpoint(pmd) {
			right: 20px;
			bottom: 40px;
			left: 20px;
		}
		h2 {
			display: flex;
			align-items: center;
			font-size: 30px;
			line-height: 0.8;
			text-transform: uppercase;
			padding: 8px 0 8px 12px;
			border-left: 4px solid $accent-color;
			@include breakpoint(pmd) {
				font-size: 60px;
				padding: 14px 0 14px 24px;
			}
			@include breakpoint(pxl) {
				font-size: 82px;
				padding: 20px 0 20px 24px;
			}
			span {
				display: block;
				&:first-child {
					width: 132px;
					@include breakpoint(pmd) {
						width: 260px;
					}
					@include breakpoint(pxl) {
						width: 350px;
					}
				}
				&:last-child {
					font-size: 64px;
					@include breakpoint(pmd) {
						font-size: 124px;
					}
					@include breakpoint(pxl) {
						font-size: 174px;
					}
				}
			}
		}
	}
	.descr-block {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		.descr-elem {
			text-align: center;
			background-color: $color-white;
			padding: 20px;
			border-radius: 16px;
			margin: 20px 0;
			@include breakpoint(pmd) {
				text-align: left;
			}
			@include breakpoint(plg) {
				margin: 5px 0;
			}
			@include breakpoint(pxl) {
				padding: 40px;
			}
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
			dt {
				font-size: 20px;
				font-weight: 600;
				text-transform: uppercase;
				margin-bottom: 12px;
				@include breakpoint(pmd) {
					font-size: 24px;
				}
				@include breakpoint(pxl) {
					margin-bottom: 20px;
				}
			}
			dd {
				color: $color-grey1;
				font-size: 16px;
				line-height: 1.4;
				@include breakpoint(pmd) {
					font-size: 20px;
				}
			}
		}
	}
}
.work-steps {
	text-align: center;
	h2 {
		font-size: 28px;
	}
	.steps-block {
		width: 330px;
		margin: 80px auto 0 auto;
		@include breakpoint(plg) {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			width: auto;
		}
		.steps-elem {
			position: relative;
			padding: 0 5px;
			margin-top: 40px;
			@include breakpoint(plg) {
				width: 20%;
				margin-top: 0;
			}
			dt {
				width: 76px;
				color: $accent-color;
				font-size: 28px;
				font-weight: 700;
				background-color: $accent-color-back;
				padding: 24px;
				border-radius: 24px;
				margin: 0 auto 24px auto;
				@include breakpoint(plg) {
					margin: 0 auto 40px auto;
				}
			}
			dd {
				font-size: 20px;
				font-weight: 500;
			}
			.arr {
				position: absolute;
				@include breakpoint(plg) {
					display: none;
				}
			}
			&.step1,
			&.step5 {
				dt {
					color: $color-white;
					background-color: $accent-color;
					box-shadow: 0 4px 12px 0 rgba(54, 94, 220, 0.6);
				}
			}
			&.step2 {
				.arr {
					right: 60px;
					bottom: 95px;
				}
			}
			&.step3 {
				.arr {
					left: 30px;
					bottom: 95px;
				}
			}
			&.step4 {
				.arr {
					right: 30px;
					bottom: 85px;
				}
			}
			&.step5 {
				.arr {
					left: 30px;
					bottom: 100px;
				}
			}
		}
		.arr-wrap {
			display: none;
			width: 0;
			position: relative;
			@include breakpoint(plg) {
				display: block;
			}
			.svg-icon {
				position: absolute;
				top: 26px;
				left: 0;
				width: 92px;
				fill: $color-grey2;
				transform: translateX(-50%);
				@include breakpoint(pxl) {
					width: 102px;
				}
				&.mirrored {
					transform: translateX(-50%) rotateX(180deg);
				}
			}
		}
	}
}
.loan {
	display: flex;
	align-items: center;
	position: relative;
	overflow: hidden;
	padding: 40px 15px;
	margin-right: -15px;
	margin-left: -15px;
	@include breakpoint(psm) {
		padding: 40px 20px;
		border-radius: 16px;
		margin-right: 0;
		margin-left: 0;
	}
	@include breakpoint(plg) {
		height: 648px;
		padding: 40px;
	}
	@include breakpoint(pxl) {
		height: 760px;
		padding: 100px;
	}
	.cover {
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.2);
	}
	.row {
		position: relative;
		z-index: 2;
		@include breakpoint(pxl) {
			margin-right: -30px;
			margin-left: -30px;
		}
	}
	.col {
		flex: 0 0 auto;
		@include breakpoint(pxl) {
			padding-right: 30px;
			padding-left: 30px;
		}
	}
	.col1 {
		@include breakpoint(pmd) {
			width: 384px;
		}
		@include breakpoint(plg) {
			width: 484px;
		}
		@include breakpoint(pxl) {
			width: 500px;
		}
	}
	.col2 {
		@include breakpoint(pmd) {
			width: 344px;
		}
		@include breakpoint(plg) {
			width: 420px;
		}
		@include breakpoint(pxl) {
			width: 440px;
		}
	}
	.res-block {
		font-size: 20px;
		text-align: center;
		background-color: $color-white;
		padding: 20px;
		border-radius: 16px;
		margin-top: 24px;
		@include breakpoint(pmd) {
			font-size: 24px;
			margin-top: 0;
		}
		@include breakpoint(plg) {
			padding: 40px;
		}
		h2 {
			color: $accent-color;
			font-weight: 600;
		}
		.res-sum {
			font-weight: 500;
			margin-top: 24px;
			@include breakpoint(pmd) {
				margin-top: 40px;
			}
		}
	}
}
.order-form-section {
	@include breakpoint(pxl) {
		padding: 0 118px;
	}
	.form-block {
		h2 {
			color: $accent-color;
			text-align: center;
		}
	}
	.row {
		@include breakpoint(pxl) {
			margin-right: -30px;
			margin-left: -30px;
		}
	}
	.col {
		flex: 0 0 auto;
		@include breakpoint(pxl) {
			padding-right: 30px;
			padding-left: 30px;
		}
	}
	.col1 {
		width: 100%;
		order: 1;
		@include breakpoint(pmd) {
			width: 50%;
			order: 0;
		}
		@include breakpoint(pxl) {
			width: 500px;
		}
	}
	.col2 {
		width: 100%;
		@include breakpoint(pmd) {
			width: 50%;
		}
		@include breakpoint(pxl) {
			width: 604px;
		}
	}
	h1 {
		font-size: 34px;
		margin-bottom: 20px;
		@include breakpoint(pxl) {
			font-size: 40px;
		}
	}
}
.examples {
	.nav {
		display: flex;
		flex-wrap: nowrap;
		overflow-y: hidden;
		overflow-x: auto;
		margin-bottom: 24px;
		@include breakpoint(plg) {
			display: block;
			margin-bottom: 0;
		}
		li {
			margin: 0 8px 0 0;
			@include breakpoint(pmd) {
				margin: 0 16px 0 0;
			}
			@include breakpoint(plg) {
				margin: 0 0 24px 0;
			}
		}
		.nav-btn {
			width: 100%;
			color: $color-grey1;
			font-size: 16px;
			font-weight: 500;
			white-space: nowrap;
			background-color: $color-light2;
			padding: 12px 16px;
			border-radius: 8px;
			@include breakpoint(pmd) {
				font-size: 20px;
				padding: 20px 24px;
				border-radius: 12px;
			}
			&.active {
				color: $accent-color;
				background-color: $accent-color-back;
			}
			&:hover {
				color: $accent-color;
			}
		}
	}
	.tab-content {
		position: relative;
		overflow: hidden;
		> .tab-pane {
			display: block;
			position: absolute;
			bottom: 100%;
			width: 100%;
			&.active {
				position: static;
			}
		}
	}
}
.accordion-section {
	.accordion-item {
		background-color: $color-white;
		border: none;
		border-radius: 12px;
		+ .accordion-item {
			margin-top: 20px;
		}
	}
	.accordion-button {
		justify-content: space-between;
		color: $color-grey1;
		font-size: 16px;
		font-weight: 500;
		background-color: transparent;
		padding: 16px 16px 12px 16px;
		border-radius: 12px;
		box-shadow: none;
		@include breakpoint(pmd) {
			font-size: 20px;
			padding: 24px 24px 20px 24px;
		}
		&::after {
			width: 11px;
			height: 6px;
			background-image: url(../img/angle-down.png);
			background-size: contain;
			margin-left: 16px;
			transform: none;
			@include breakpoint(pmd) {
				width: 20px;
				height: 11px;
			}
		}
		&:not(.collapsed) {
			color: $accent-color;
			&::after {
				background-image: url(../img/angle-up.png);
			}
		}
	}
	.accordion-body {
		font-size: 12px;
		line-height: 1.4;
		padding: 12px 48px 16px 16px;
		@include breakpoint(pmd) {
			font-size: 16px;
			padding: 20px 72px 24px 24px;
		}
	}
}
.cases {
	.prod-descr {
		height: auto;
		@include breakpoint(pxl) {
			height: 648px;
		}
		.descr-cont {
			@include breakpoint(pxl) {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
	}
}
.slogan-articles {
	.row-c {
		position: relative;
		padding-left: 15px;
		@include breakpoint(psm) {
			padding-left: 20px;
		}
		@include breakpoint(pmd) {
			padding-left: 0;
		}
		+ .row-c {
			margin-top: 80px;
		}
	}
	.coll {
		padding-left: 18px;
		border-left: 2px solid $accent-color;
		@include breakpoint(pmd) {
			padding-left: 20px;
			border: none;
		}
		@include breakpoint(plg) {
			padding-right: 40px;
		}
		@include breakpoint(pxl) {
			padding-right: 60px;
		}
	}
	.colr {
		padding-left: 18px;
		border-left: 2px solid $accent-color;
		@include breakpoint(pmd) {
			position: relative;
			padding-left: 20px;
		}
		@include breakpoint(plg) {
			padding-left: 40px;
		}
		@include breakpoint(pxl) {
			padding-left: 60px;
		}
	}
	.circle {
		position: absolute;
		top: 0;
		left: 11px;
		width: 10px;
		height: 10px;
		background-color: $accent-color;
		border-radius: 50%;
		@include breakpoint(psm) {
			left: 16px;
		}
		@include breakpoint(pmd) {
			left: -6px;
		}
	}
	.angle {
		position: absolute;
		bottom: -2px;
		left: 11px;
		width: 10px;
		height: 10px;
		border-right: 2px solid $accent-color;
		border-bottom: 2px solid $accent-color;
		transform: rotate(45deg);
		@include breakpoint(psm) {
			left: 16px;
		}
		@include breakpoint(pmd) {
			left: -6px;
		}
	}
	.img-wrap {
		height: 325px;
		overflow: hidden;
		border-radius: 12px;
		@include breakpoint(plg) {
			height: 432px;
		}
		@include breakpoint(pxl) {
			height: 400px;
		}
	}
	h2 {
		text-transform: uppercase;
		margin-bottom: 20px;
	}
}
.main-footer {
	color: $color-white;
	background-color: $color-black;
	padding: 20px 0;
	margin-top: 80px;
	.footer-inner {
		text-align: center;
		@include breakpoint(pmd) {
			text-align: left;
		}
		@include breakpoint(plg) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.footer-elem {
		@include breakpoint(plg) {
			display: flex;
			align-items: center;
		}
	}
	.logo-link {
		font-size: 40px;
		font-weight: 500;
		line-height: 1;
		@include breakpoint(plg) {
			margin-right: 40px;
		}
		@include breakpoint(pxl) {
			margin-right: 60px;
		}
	}
	.links-group-link {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		margin-top: 40px;
		@include breakpoint(pmd) {
			justify-content: flex-start;
		}
		@include breakpoint(plg) {
			margin-top: 0;
			margin-right: 40px;
		}
		@include breakpoint(pxl) {
			margin-right: 60px;
		}
		.svg-icon {
			fill: $color-white;
			margin-right: 12px;
		}
	}
	.soc-links {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
		@include breakpoint(pmd) {
			justify-content: flex-start;
		}
		@include breakpoint(plg) {
			margin-top: 0;
		}
		li + li {
			margin-left: 20px;
		}
		.svg-icon {
			fill: $color-white;
		}
	}
	.signature {
		font-size: 12px;
		margin-top: 80px;
		@include breakpoint(plg) {
			font-size: 16px;
			margin-top: 0;
		}
		a {
			text-decoration: underline;
			text-underline-offset: 0.25em;
			text-decoration-thickness: 1px;
		}
	}
}
/* </main */

/* modals */
.modal {
	backdrop-filter: blur(3px);
	h2 {
		color: $accent-color;
		text-align: center;
	}
	.close-btn {
		.svg-icon {
			fill: $color-grey2;
			transition: fill 0.2s;
		}
		&:hover {
			.svg-icon {
				fill: $color-grey1;
			}
		}
	}
}
.modal-backdrop {
	&.show {
		opacity: 0.4;
	}
}
.callback {
	.modal-dialog {
		@include breakpoint(psm) {
			max-width: 440px;
		}
	}
	.modal-content {
		border: none;
		border-radius: 20px;
	}
	.modal-header {
		position: relative;
		padding: 20px 20px 0 20px;
		border: none;
		@include breakpoint(psm) {
			padding: 40px 40px 0 40px;
		}
		.close-btn {
			position: absolute;
			top: 24px;
			right: 24px;
		}
	}
	.modal-body {
		padding: 40px 20px;
		@include breakpoint(psm) {
			padding: 40px;
		}
	}
	.modal-footer {
		padding: 0 20px 20px 20px;
		border: none;
		@include breakpoint(psm) {
			padding: 0 40px 40px 40px;
		}
		> * {
			margin: 0;
		}
	}
}

.prod-card-modal,
.gallery-modal {
	.modal-dialog {
		max-width: none;
	}
	.modal-content {
		display: block;
		background-color: transparent;
		border: none;
		border-radius: 0;
	}
	.modal-inner {
		position: relative;
		.close-btn {
			position: absolute;
			top: 16px;
			right: 0;
			z-index: 1;
			@include breakpoint(psm) {
				right: 16px;
			}
		}
	}
}
.gallery-modal {
	.modal-dialog {
		margin-right: auto;
		margin-left: auto;
	}
	.modal-inner {
		.close-btn {
			right: 16px;
		}
	}
}
/* </modals */